import { commonService } from "../../services/common.service";
import errorHandling from "@/handleError/errHandling";

const state = {
  apiKey: "",
  apiLoader: false,
};
const mutations = {
  resetState(state) {
    state.apiKey = "";
    state.apiLoader = false;
  },
  setApiLoader(state, payload) {
    state.apiLoader = payload;
  },
  setApiKey(state, payload) {
    state.apiKey = payload;
  },
};
const actions = {
  async generateApiKey({ state, commit, dispatch }, payload) {
    let json = {
      source: "WEB",
    };
    await commonService.generateApiKey(json).then((response) => {
      if (response.status == 200) {
        dispatch("getApiKey");
      }
    });
  },
  async getApiKey({ state, commit }) {
    commit("setApiLoader", true);
    await commonService
      .getApiKey()
      .then(
        (res) => {
          if (res) {
            commit("setApiKey", res.data.result);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setApiLoader", false);
      });
  },
  async regenerateApiKey({ state, commit, dispatch }, payload) {
    let json = {
      source: "WEB",
    };
    await commonService.regenerateApiKey(json).then(
      (response) => {
        if (response) {
          dispatch("getApiKey");
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },
};
const getters = {};
const appsStore = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default appsStore;
