import { env } from "../helpers/env";
import { getHeaders } from "../helpers/auth-header";
import axios from "axios";

const AXIOS = axios.create({
  baseURL: env().BASEURL,
});

const module = 'auth'

export const loginService = {
  // WEB Login Methods
  verifyClient,
  validatePassword,
  sendWebAuthOtp,
  verifyWebAuthOtp,
  resetPassword,
  forgotPass,
  forgotPassVerify,
  unblockAcc,
  unblockAccVerify,
  // TOTP
  registerTotp,
  getScannerData,
  enableTotp,
  verifyTotp,
  changePassword
};

function verifyClient(payload) {
  return AXIOS.post(`${module}/access/client/verify`, payload)
}

function validatePassword(payload) {
  return AXIOS.post(`${module}/auth/login`, payload)
}

function sendWebAuthOtp(payload) {
  return AXIOS.post(`${module}/auth/otp/send`, payload, getHeaders(false, false, false))
}

function verifyWebAuthOtp(payload) {
  return AXIOS.post(`${module}/auth/otp/validate`, payload)
}

function resetPassword(payload) {
  const requestOptions = {
    headers: loginAuthHead(),
  };
  return AXIOS.post(
    `${module}/access/pwd/reset`,
    payload,
    requestOptions
  )
}
function changePassword(payload) {

  
  return AXIOS.post(`${module}/auth/change/pwd`, payload)
}

function forgotPass(payload) {
  
  return AXIOS.post(`${module}/auth/forgot/pwd`, payload)
}

function forgotPassVerify(payload) {
  return AXIOS.post(`${module}/access/pwd/forget/verify`, payload)
}

function unblockAcc(payload) {
  return AXIOS.post(`${module}/auth/unblock`, payload)
}

function unblockAccVerify(payload) {
  return AXIOS.post(`${module}/access/client/unblock/verify`, payload)
}

// TOTP APIS 
function registerTotp(payload) {
  return AXIOS.post(
    `${module}/auth/scanner/generate`,
    payload,
    getHeaders(false, false, false)
  )
}

function getScannerData(payload) {
  return AXIOS.post(
    `${module}/access/scanner/get`,
    payload,
    getHeaders(false, false, false)
  )
}

function enableTotp(payload) {
  return AXIOS.post(
    `${module}/access/topt/enable`,
    payload,
    getHeaders(false, false, false)
  )
}

function verifyTotp(payload) {
  return AXIOS.post(
    `${module}/access/topt/verify`,
    payload,
    getHeaders(false, false, false)
  )
}
