var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"notialerts_container"}},[_c('div',{staticClass:"sticky z5",class:_vm.$vuetify.theme.dark ? 'lighttoolTipColor' : 'bg-F9F9FB'},[_c('div',{staticClass:"row ma-0 justify-space-between"},[_c('div',{staticClass:"py-2",attrs:{"id":"holdings_button_list"}},[_c('tab-menu',{attrs:{"buttonsList":_vm.notiAlertTabList,"tabName":'Notifications'}})],1),(
          _vm.searchNotifification == '' ? _vm.notificationList : _vm.notiSearchFilter()
        )?_c('span',{staticClass:"d-flex py-3 px-3 align-middle"},[_c('v-sheet',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.resetSearch),expression:"resetSearch"}],staticClass:"search-box mx-2"},[_c('span',{staticClass:"searchwhole",attrs:{"id":"noti_search_input_container"}},[_c('customIcon',{staticClass:"searchimg",attrs:{"name":'search',"width":'16',"height":'16',"color":_vm.$store.state.amogaColor}}),_c('span',{staticClass:"searchinput"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchNotifification),expression:"searchNotifification"}],staticClass:"placeholdsearch",attrs:{"id":"notisearch","placeholder":"Search","autocorrect":"off","oninput":"this.value = this.value.toUpperCase()"},domProps:{"value":(_vm.searchNotifification)},on:{"input":function($event){if($event.target.composing)return;_vm.searchNotifification=$event.target.value}}})])],1)])],1):_vm._e()]),_c('hr',{staticClass:"mx-4 my-1 v-divider",class:_vm.$vuetify.theme.dark ? '' : 'theme--light',attrs:{"role":"separator","aria-orientation":"horizontal"}})]),_c('div',[_c('div',[(
          _vm.searchNotifification == ''
            ? _vm.notificationList.length > 0
            : _vm.notiSearchFilter().length > 0
        )?_c('v-container',[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""},model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}},_vm._l((_vm.searchNotifification == ''
              ? _vm.notificationList
              : _vm.notiSearchFilter()),function(item,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-expansion-panel-content',[_c('div',{class:item.messageType == 'ResearchCall' ? 'notiPointer' : '',on:{"click":function($event){return _vm.handleMessageClick(item.messageType)}}},[_vm._v(" "+_vm._s(item.message)+" ")])])],1)}),1)],1):_c('div',[_c('noData',{attrs:{"id":"Nodata_niti_component","noDataValue":"Records"}})],1)],1)]),_c('div'),(_vm.$store.state.isMobileView)?_c('bottomSheet',{attrs:{"id":"bottomsheet_holdings_component","currentData":_vm.currentSheetData},on:{"from-child":_vm.getChildData}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }