const state = {
  refreshArray: "",
  popOutRefresh: "",
  currentChartData: "",
  tradeChartData: "",
  chartUrl: "",
  streamSymbol: [],
  currentPopoutSymbol: "",
  chartIndicators:''
};

const mutations = {
  resetState(state) {
    state.nrefreshArray = "";
    state.popOutRefresh = "";
    state.currentChartData = "";
    state.tradeChartData = "";
    state.chartUrl = "";
  },
  setRefreshArray(state, payload) {
    state.refreshArray = payload;
  },
  setPopOutRefresh(state, payload) {
    state.popOutRefresh = payload;
  },
  setChartData(state, payload) {
    state.currentChartData = payload;
    localStorage.setItem("chartData", JSON.stringify(payload));
  },
  setTardeData(state, payload) {
    state.tradeChartData = payload;
  },
  setChartUrl(state, payload) {
    state.chartUrl = payload;
  },
  setCurrentPopoutSymbol(state, payload) {
    state.currentPopoutSymbol = payload;
  },
  setStreamSymbol(state, payload) {
    state.streamSymbol = payload;
  },
  setChartIndicators(state, payload){
    state.chartIndicators = payload
    localStorage.setItem('chartIndicators' ,JSON.stringify(payload))
  },
};

const actions = {};
const getters = {
  getChartSubscribe:(state) => state.streamSymbol
};
const chart = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters,
};
export default chart;
