import Vue from "vue";
import { commonService } from "../../services/common.service";
import router from "../../router";
import Cryptojs from "vue-cryptojs";
Vue.use(Cryptojs);
import { env } from "../../helpers/env";
import audioPlay from "../../../public/tone.mp3";
import errorHandling from "../../handleError/errHandling";
let socket;
const wsConnection = {
  namespaced: true,

  state: {
    connectionStatus: false,
    previousSubsription: null,
    preDepthSubsription: "",
    preDepthPayload: "",
    isReconnect: false,
    isValidSession: false,
    isConnect: false,
    otherSubscription: "",
    validToken: "",
    chartSubscribe: null,
    heartBeatInterval: "",
    webSocketCount: 0,
    wsSession: "",
    wsSessionLoader: ''
  },

  mutations: {
    resetState(state, payload) {
      state.connectionStatus = false;
      state.previousSubsription = null;
      state.preDepthSubsription = "";
      state.preDepthPayload = "";
      state.isReconnect = false;
      state.isValidSession = false;
      state.isConnect = false;
      state.otherSubscription = "";
      state.validToken = "";
      state.chartSubscribe = null;
      state.heartBeatInterval = "";
      state.webSocketCount = 0;
      state.wsSession = "";
    },
    setPreviousSubsription(state, payload) {
      state.previousSubsription = payload;
    },
    setConnectionStatus(state, payload) {
      state.connectionStatus = payload;
    },
    setPreDepthSubsription(state, payload) {
      state.preDepthSubsription = payload;
    },
    setPreDepthPayload(state, payload) {
      state.preDepthPayload = payload;
    },
    setReconnect(state, payload) {
      state.isReconnect = payload;
    },
    setOtherSubscription(state, payload) {
      state.otherSubscription = payload;
    },
    setValidToken(state, payload) {
      state.validToken = payload;
    },
    setChartSubscribe(state, payload) {
      state.chartSubscribe = payload;
    },
    setIsValidSession(state, payload) {
      state.isValidSession = payload;
    },
    setWebSocketCount(state, payload) {
      state.webSocketCount = payload;
    },
    setWsSession(state, payload) {
      state.wsSession = payload;
    },
    setWsSessionLoader(state, payload){
      state.wsSessionLoader = payload
    }
  },

  actions: {
    async connect(
      { state, commit, dispatch, rootGetters, rootState },
      payload
    ) {
      socket = new WebSocket(env().WEBSOCKETURL);

      socket.onopen = async function () {
        await dispatch("connectionRequest");
      };

      socket.onmessage = function (msg) {
        var responseFeed = JSON.parse(msg.data);

        if (
          responseFeed.t == "om" &&
          responseFeed.hasOwnProperty("norenordno")
        ) {
          dispatch("orderNotification", responseFeed);
          var audio = new Audio(audioPlay);
          audio.play();
        }
        if (
          !!responseFeed.t &&
          responseFeed.t == "ck" &&
          responseFeed.s == "OK"
        ) {
          commit("setConnectionStatus", true);
          commit("setWebSocketCount", 0);
          if (state.isReconnect || !state.previousSubsription) {
            dispatch("reconnect");
            commit("setReconnect", false);
          }
          dispatch('callOrderStatus')
          state.heartBeatInterval = setInterval(() => dispatch('callHeartBeat'), 50000)
        } else if (
          !!responseFeed.t &&
          responseFeed.t == "ck" &&
          responseFeed.s == "NOT_OK"
        ) {
          errorHandling.toaster(
            "",
            "danger",
            "Something went wrong. Please try again later",
            3000,
            "top-right",
            " ",
            " "
          );
        }
        if (!!responseFeed.tk && !!responseFeed.e) {
          let tickers = window.tickers;
          commit("setValidToken", `${responseFeed.e}|${responseFeed.tk}`);
          if (tickers) {
            if (tickers[`${responseFeed.e}|${responseFeed.tk}`]) {
              !responseFeed.o &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].o
                ? (responseFeed.o =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].o)
                : "";
              !responseFeed.h &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].h
                ? (responseFeed.h =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].h)
                : "";
              !responseFeed.l &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].l
                ? (responseFeed.l =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].l)
                : "";
              !responseFeed.c &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].c
                ? (responseFeed.c =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].c)
                : "";
              !responseFeed.lp &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].lp
                ? (responseFeed.lp =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].lp)
                : "";
              !responseFeed.v &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].v
                ? (responseFeed.v =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].v)
                : "";
              !responseFeed.bp1 &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].bp1
                ? (responseFeed.bp1 =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].bp1)
                : "";
              !responseFeed.sp1 &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].sp1
                ? (responseFeed.sp1 =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].sp1)
                : "";
              !responseFeed.pc &&
              tickers[`${responseFeed.e}|${responseFeed.tk}`].pc
                ? (responseFeed.pc =
                    tickers[`${responseFeed.e}|${responseFeed.tk}`].pc)
                : "";
            }
            tickers[`${responseFeed.e}|${responseFeed.tk}`] = responseFeed;
            window.tickers = tickers;

            if (window.head && typeof window.head?.callback === "function") {
              window.head.callback();
            }
            if (
              window.positionsBook &&
              typeof window.positionsBook?.callback === "function"
            ) {
              window.positionsBook.callback();
            }
            if (
              window.holding &&
              typeof window.holding?.callback === "function"
            ) {
              window.holding.callback();
            }
            if (
              window.exchange &&
              typeof window.exchange?.callback === "function"
            ) {
              window.exchange.callback();
            }
            if (window.order && typeof window.order?.callback === "function") {
              window.order.callback();
            }
            if (
              window.basket &&
              typeof window.basket?.callback === "function"
            ) {
              window.basket.callback();
            }

            if (
              window.alerts &&
              typeof window.alerts?.callback === "function"
            ) {
              window.alerts.callback();
            }

            if (window.ow && typeof window.ow?.callback === "function") {
              window.ow.callback();
            }

            if (
              window.goodTillYh &&
              typeof window.goodTillYh?.callback == "function"
            ) {
              window.goodTillYh.callback();
            }
            if (
              window.goodTillTriggered &&
              typeof window.goodTillTriggered?.callback == "function"
            ) {
              window.goodTillTriggered.callback();
            }
            if (
              window.optionChain &&
              typeof window.optionChain?.callback === "function"
            ) {
              window.optionChain.callback();
            }
            if (window.chart && typeof window.chart?.callback === "function") {
              window.chart.callback();
            }
            if (
              window.popout &&
              typeof window.popout?.callback === "function"
            ) {
              window.popout.callback();
            }
            if (window.watch && typeof window.watch?.callback === "function") {
              window.watch.callback();
            }
            if (window.homeHolding && typeof window.homeHolding?.callback === "function") {
              window.homeHolding.callback();
            }
            
          }
        }
      };

      socket.onerror = function (err) {
        socket.onclose();
        commit("setConnectionStatus", false);
      };

      socket.onclose = function (err) {
        socket.close();
        if(navigator.onLine){
          dispatch('reconnectRequest', 'connect')
        }
      };
    },

    async send({ state, commit, dispatch }, msg) {
      if (!!socket.readyState && socket.readyState == 1) {
        try {
          socket.send(msg);
        } catch (err) {}
      } else if (!!socket.readyState && socket.readyState == 0) {
        setTimeout(() => {
          socket.send(msg);
        }, 900);
      }
    },

    async connectionRequest({ state, dispatch, rootGetters }) {
      var token = state.wsSession;
      var userId = await rootGetters["login/getUCC"];

      var initCon = {
        susertoken: token,
        t: "c",
        actid: userId,
        uid: userId,
        source: rootGetters['getEnv'] == 'Live' ? "COD" : "API",
      };
      dispatch("send", JSON.stringify(initCon));
    },

    async establishConnection({ state, dispatch }, payload) {
      if (state.connectionStatus == false) {
        await dispatch("connectionRequest");
      }
      await dispatch("send", JSON.stringify(payload));
    },

    async websocketSubscription(
      { state, commit, dispatch, rootGetters, rootState },
      payload
    ) {
      if (state.connectionStatus) {
        let channel = "";
        let path = router.currentRoute.path;
        let tab = rootState["queries"];
        let where = payload.where;
        let prevList = [];
        let mwData = rootGetters["marketWatch/getMwList"];
        let curList = [...rootState["header"]["headerArray"], ...payload.data];
        if (path == "/home") {
          where == "holdings" ? (prevList = mwData) : "";
          where == "mkWatch"
            ? (prevList = rootGetters["holdings/getHoldingsData"])
            : "";
        }

        if (path == "/orders") {
          if (where == "mkWatch") {
            tab.orders.query.tab == "Pending"
              ? (prevList = rootGetters["order/getOpenOrders"])
              : "";

            tab.orders.query.tab == "Basket"
              ? (prevList = rootGetters["basket/getBasketScrips"])
              : "";
          }

          where == "orders" ? (prevList = mwData) : "";
          where == "basket" ? (prevList = mwData) : "";
        }
        if (path == "/positions") {
          where == "mkWatch"
            ? (prevList = rootGetters["position/getCurrentTableData"])
            : "";
          where == "position" ? (prevList = mwData) : "";
        }
        if (path == "/holdings") {
          where == "mkWatch"
            ? (prevList = rootGetters["holdings/getHoldingsData"])
            : "";
          where == "holdings" ? (prevList = mwData) : "";
        }
        if (path == "/exchange") {
          where == "mkWatch"
            ? (prevList = rootGetters["home/getExchangeData"])
            : "";

          where == "exchange" ? (prevList = mwData) : "";
        }
        if (path == "/settings" || path == "/apps" || path == "/funds") {
          where == "settings" || where == "apps" || where == "funds"
            ? (prevList = mwData)
            : "";
          where == "mkWatch" ? (prevList = []) : "";
        }
        if (path == "/optionchain") {
          where == "mkWatch" ? (prevList = rootGetters["oc/ocList"]) : "";
        }
        if (path == "/popout") {
          prevList = [];
          curList =
            rootGetters["chart/getChartSubscribe"]?.length > 0 &&
            rootGetters["chart/getChartSubscribe"]
              ? rootGetters["chart/getChartSubscribe"]
              : [];
        }
        if (path == "/chart") {
          where == "chart" || where == "tradingChart"
            ? (prevList = mwData)
            : (prevList = []);
          where == "mw"
            ? rootGetters["chart/getChartSubscribe"]?.length > 0 &&
              rootGetters["chart/getChartSubscribe"]
              ? (prevList = rootGetters["chart/getChartSubscribe"])
              : (prevList = [])
            : "";
        }
        curList = [...curList, ...prevList];

        curList.forEach((element) => {
          channel += `${element["exchange"]}|${element["token"]}#`;
        });

        if (channel != "" && !!channel) {
          let tempChannel = channel.substring(0, channel.length - 1);

          tempChannel = tempChannel.split("#");

          tempChannel = new Set(tempChannel);

          let uniqueChannel = "";
          let unique = [...tempChannel];

          unique.forEach((element) => {
            uniqueChannel += element + "#";
          });

          uniqueChannel = uniqueChannel.substring(0, uniqueChannel.length - 1);

          let prevSplit = state.previousSubsription
            ? state.previousSubsription?.split("#")
            : [];
          commit("setPreviousSubsription", uniqueChannel);

          let sub_arr = unique.filter((x) => !prevSplit.includes(x));
          let un_arr = prevSplit.filter((x) => !unique.includes(x));

          let sub_Channel = "";
          let un_Channel = "";

          sub_arr.forEach((element) => {
            sub_Channel += element + "#";
          });
          un_arr.forEach((element) => {
            un_Channel += element + "#";
          });

          sub_Channel = sub_Channel.substring(0, sub_Channel.length - 1);
          un_Channel = un_Channel.substring(0, un_Channel.length - 1);

          if (un_arr.length > 0) {
            let json = {
              k: un_Channel,
              t: "u",
            };

            await dispatch("establishConnection", json);
          }

          if (sub_arr.length > 0) {
            let json = {
              k: sub_Channel,
              t: "t",
            };

            await dispatch("establishConnection", json);
          }
          await dispatch("setToken", { sub_arr, un_arr });
        }
      }
    },

    async ortherSubscription(
      { state, commit, dispatch, rootGetters, rootState },
      payload
    ) {
      if (state.connectionStatus && payload.sub) {
        let prevArr = state.previousSubsription
          ? state.previousSubsription.split("#")
          : [];

        let index = prevArr.indexOf(payload.sub);

        let task =
          payload.statge == "initial"
            ? "t"
            : index == -1 && payload.statge == "esc"
            ? "u"
            : "";

        if (index == -1) {
          let json = {
            k: payload.sub,
            t: task,
          };
          let sub_arr = [];
          let un_arr = [];
          if (task == "u") {
            !payload.sub.includes("#")
              ? un_arr.push(payload.sub)
              : (un_arr = payload.sub.split("#"));
          }
          if (task == "t") {
            !payload.sub.includes("#")
              ? sub_arr.push(payload.sub)
              : (sub_arr = payload.sub.split("#"));
          }

          await dispatch("establishConnection", json);
          await dispatch("setToken", { sub_arr, un_arr });
          let prevSub;
          if (payload.statge == "initial") {
            state.otherSubscription
              ? (prevSub = state.otherSubscription + "#" + payload.sub)
              : (prevSub = payload.sub);
          } else {
            prevSub = null;
          }
          commit("setOtherSubscription", prevSub);
        } else {
          if (window.alerts && typeof window.alerts?.callback === "function") {
            window.alerts.callback();
          }
          if (window.ow && typeof window.ow?.callback === "function") {
            window.ow.callback();
          }

          if (
            window.goodTillTriggered &&
            typeof window.goodTillTriggered?.callback == "function"
          ) {
            window.goodTillTriggered.callback();
          }
        }
      }
    },

    async depthSubscription({ state, commit, dispatch, rootGetters }, payload) {
      if (state.connectionStatus) {
        commit("setPreDepthPayload", payload);

        if (state.preDepthSubsription) {
          let json = {
            k: state.preDepthSubsription,
            t: "ud",
          };

          await dispatch("establishConnection", json);
        }
        // payload ? commit("setPreDepthSubsription", payload) : "";
        if (payload != "") {
          commit("setPreDepthSubsription", payload);
          let json = {
            k: payload,
            t: "d",
          };

          await dispatch("establishConnection", json);
        }
      }
    },

    async reconnect(
      { state, commit, dispatch, rootGetters, rootState },
      payload
    ) {
      clearInterval(state.heartBeatInterval);
      commit("setPreviousSubsription", null);

      if (
        rootState.marketWatch.watchList.length > 0 &&
        router.currentRoute.path != "popout"
      ) {
        dispatch("marketWatch/checkSocketStatus", "", { root: true });
      }

      commit("setPreDepthSubsription", null);
      router.currentRoute.path == "/positions"
        ? dispatch("position/checkWebsocketStatus", "", { root: true })
        : "";
      router.currentRoute.path == "/holdings" ||
      router.currentRoute.path == "/home"
        ? dispatch("holdings/checkWebsocketStatus", "", { root: true })
        : "";
      router.currentRoute.path == "/orders"
        ? dispatch("order/checkWebsocketStatus", "", { root: true })
        : "";

      router.currentRoute.path == "/exchange"
        ? dispatch("home/checkWebsocketStatus", "", { root: true })
        : "";
      if (state.preDepthSubsription) {
        await dispatch("depthSubscription", state.preDepthPayload);
      }
    },

    async websocketClose({ state,commit }, payload) {
      socket ? socket.close() : "";
      await commit("resetState");
      clearInterval(state.heartBeatInterval);
    },

    setToken({}, payload) {
      let tickers = window.tickers ? window.tickers : {};

      for (let item of payload.sub_arr) {
        let exch = item.split("|")[0];
        let token = item.split("|")[1];
        tickers[`${exch}|${token}`] = null;
      }

      var obj = tickers;
      for (let item of payload.un_arr) {
        let exch = item.split("|")[0];
        let token = item.split("|")[1];
        for (let k in obj) {
          if (k == `${exch}|${token}`) {
            delete obj[k];
          }
        }
      }

      window.tickers = obj;
    },

    async callHeartBeat({ state, commit, dispatch, rootGetters }, payload) {
      if (state.connectionStatus) {
        let json = {
          k: "",
          t: "h",
        };

        await dispatch("establishConnection", json);
      }
    },
    async callOrderStatus({ state, commit, dispatch, rootGetters }, payload) {
      if (state.connectionStatus) {
        let json = {
          actid: rootGetters["login/getUCC"],
          t: "o",
        };

        await dispatch("establishConnection", json);
      }
    },

    async getWsSession({ state, rootGetters, dispatch, commit }, payload) {
    commit('setWsSessionLoader' , true)
      await commonService.getWsSession().then(
        (response) => {
          if (response.status === 200 && response.data.status == "Ok") {
            commit("setWsSession", response.data.result[0]?.key);
            !state.isConnect ? dispatch("connect", "createSession") : "";
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      ).finally(()=> { commit('setWsSessionLoader' , false)})
    },

    async orderNotification({ state, commit, dispatch, rootGetters }, feed) {
      if (feed) {
        let tradSym = feed.tsym ? feed.tsym : "";
        let trantype = feed.trantype
          ? feed.trantype == "B"
            ? "BUY"
            : "SELL"
          : "";
        let ordNo = feed.norenordno ? feed.norenordno : "";
        let status = feed.status ? feed.status : "";
        let qty = feed.qty ? feed.qty : "";
        let price = feed.prc ? feed.prc : "";
        let prcType = feed.prctyp ? feed.prctyp : "";
        let rejReason = feed.rejreason ? feed.rejreason : "";
        let avgPrc = feed.avgprc ? feed.avgprc : "";
        let path = router.currentRoute?.path;
        let isHaveCnc = false;
        let reportType = feed.reporttype ? feed.reporttype.toLowerCase() : "";

        if (status == "REJECTED" || reportType.includes("rejected")) {
          errorHandling.toaster(
            `${tradSym}`,
            "danger",
            `${status} Qty : ${qty} - ${trantype} - ${prcType} - ${rejReason}`,
            3000,
            "top-right",
            " ",
            " "
          );
        } else if (status == "OPEN") {
          errorHandling.toaster(
            `${tradSym}`,
            "primary",
            `${status}  Qty : ${qty} ${trantype} ${prcType} @ ${price}`,
            3000,
            "top-right",
            " ",
            " "
          );
        } else if (status == "COMPLETE") {
          setTimeout(() => {
            this.dispatch("position/getPositions", { root: true });
          }, 500);

          errorHandling.toaster(
            `${tradSym}`,
            "success",
            `${status}  Qty : ${qty} ${trantype} ${prcType} @ ${
              avgPrc ? avgPrc : price
            }`,
            3000,
            "top-right",
            " ",
            " "
          );
        } else if (status == "CANCELED") {
          errorHandling.toaster(
            `${tradSym}`,
            "primary",
            `${status}  Qty : ${qty} ${trantype} ${prcType} @ ${
              avgPrc ? avgPrc : price
            }`,
            3000,
            "top-right",
            " ",
            " "
          );
        }
        isHaveCnc = feed?.pcode == "C" ? true : false;
        if (path == "/orders") {
          dispatch("order/getUserOrders", "", { root: true });
        }
        if (
          path == "/holdings" ||
          (path == "/dash" && status == "COMPLETE" && isHaveCnc)
        ) {
          dispatch("holdings/getUserHoldings", "", { root: true });
        }
        if (
          path == "/position" &&
          (status == "COMPLETE" || status == "OPEN" || status == "CANCELED")
        ) {
          dispatch("positions/getUserPositions", "", { root: true });
        }
        if (status == "COMPLETE" && path == "/orders") {
          dispatch("order/getTradeBook", "", { root: true });
        }
      }
    },
   async backGroundCheck({state, dispatch}, payload){
      if(navigator.onLine){
          if(payload == 'connect' && socket && socket.readyState != 1){
            if(!state.wsSessionLoader){
              await  dispatch('websocketClose')
              await dispatch('getWsSession', 'backGroundCheck')
            }
          }
      
      }else{
        console.error(`You Don't have network, Please check your network connection...`)
      }
    },
    reconnectRequest({state, commit, dispatch, rootGetters}, payload){
      commit("setWebSocketCount", state.webSocketCount + 1);
        if (
          rootGetters["login/getAccessToken"] &&
          router.currentRoute.path !== "/" &&
          state.webSocketCount < 3
        ) {
          commit("setConnectionStatus", false);
          commit("setReconnect", true);
          dispatch("connect");
        }
    }
  },

  getters: {
    getConnectionStatue: (state) => {
      return state.connectionStatus;
    },
    getOtherSubscription: (state) => {
      return state.otherSubscription;
    },
    getPreviousSubscription: (state) => state.previousSubsription,
    getWsSession: (state) => state.wsSession,
  },
};

export default wsConnection;
