import { commonService } from "../../services/common.service";

import errorHandling from "../../handleError/errHandling";

const state = {
  basketList: [],
  basketScrips: [],
  currentBasketData: "",
  basketLoader: false,
  searchLoader: false,
  isBasket: false,
  basketMargin: "",
  basketOwType: "",
  basketCreateDialog: false,
  isBasketList: false,
  isRename: false,
  searchListForBasket: [],
  isConfirmDialog: false,
  isBasketEdit: false,
  setBasketName: "",
};

const actions = {
  checkWebsocketStatus({ state, dispatch, rootGetters }) {
    if (rootGetters["wsConnection/getConnectionStatue"]) {
      var tempData = {
        data: state.basketScrips,
        where: "basket",
      };

      dispatch("wsConnection/websocketSubscription", tempData, { root: true });
    }
  },
  async getBasketList(
    { state, commit, dispatch, rootGetters, rootState },
    payload
  ) {
    commit("setLoader", true);

    if (payload?.from != "close") {
      commit("setLoader", true);
    }

    await commonService
      .getBasketData()
      .then(
        (resp) => {
          if (resp.data.status == "Ok" && resp.data.message == "Success") {
            dispatch("setBasketList", resp.data.result);
            let arr = rootState.orderTabList;
            arr.forEach((el) => {
              if (el.name == "Basket") {
                el.value = el.name;
                el.value =
                  resp.data.result?.length > 0
                    ? el.value + " " + `(${resp.data?.result.length})`
                    : el.value;
              }
            });
            if (
              rootGetters["basket/getIsBasketList"] &&
              rootGetters["basket/getcurrentBasketData"] &&
              resp.data.result.length > 0
            ) {
              let currentItem = resp.data.result.filter((el) => {
                return (
                  el.basketId ==
                  rootGetters["basket/getcurrentBasketData"].basketId
                );
              });
              commit("setCurrentBasketData", currentItem[0]);
            }
          } else {
            dispatch("setBasketList", []);
            let arr = rootState.orderTabList;
            arr.forEach((el) => {
              if (el.name == "Basket") {
                el.value = el.name;
              }
            });
          }
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },

  async createBasket({ state, commit, dispatch, rootGetters }, payload) {
    let json = {
      basketName: payload.basketName,
    };
    await commonService.createBasket(json).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          dispatch("getBasketList");
          commit("setBasketCreateDialog", false);
          errorHandling.toaster(
            "",
            "success",
            'Basket created successfully',
            4500,
            "",
            " ",
            " "
          );
        } else {
          errorHandling.toaster(
            "",
            "danger",
            resp.data.message,
            4500,
            "",
            " ",
            " "
          );
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },

  async renameBasket({ state, commit, dispatch, rootGetters }, payload) {
    let json = {
      basketId: payload.basketId,
      basketName: payload.basketName,
    };
    await commonService.renameBasket(json).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          let basketName = "";
          resp.data.result.forEach((el) => {
            if (el.basketId == payload.basketId) {
              basketName = el.basketName;
            }
          });

          commit("setBasketNameUpdate", basketName);
          dispatch("getBasketScrips", {
            basketId: payload.basketId,
          });
        } else {
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },

  async deleteBasket({ state, commit, dispatch, rootGetters }, payload) {
    await commonService.deleteBasket(payload.id).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          dispatch("setBasketList", resp.data.result);
          commit("setIsConfirmDialog", false);
          commit("setIsBasketList", false);
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },

  async getBasketScrips({ state, commit, dispatch, rootGetters }, payload) {
    commit("setLoader", true);
    await commonService
      .getBasketScrips(payload.basketId)
      .then(
        (resp) => {
          if (resp.data.status == "Ok" && resp.data.message == "Success") {
            let basketArray = resp.data.result;
            basketArray.forEach((el) => {
              el.ltp = el.ltp ? el.ltp : "0.00";
            });
            commit("setBasketScrips", resp.data?.result);
          } else {
            commit("setBasketScrips", []);
          }
          dispatch("getBasketMargin");
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },

  async addScripInBasket({ state, commit, dispatch, rootGetters }, payload) {
    let data = payload[0];
    let json = { basketId: state.currentBasketData.basketId };
    let json1 = {
      exchange: data.exchange,
      token: data.token,
      tradingSymbol: data.tradingSymbol,
      qty: data.qty,
      price: data.price,
      product: data.product,
      transType: data.transType == "B" ? "BUY" : "SELL",
      priceType: data.priceType,
      orderType: data.orderType,
      ret: data.ret,
      triggerPrice: data.triggerPrice,
      disclosedQty: data.disclosedQty,
      MktProtection: data.MktProtection,
      target: data.target,
      stopLoss: data.stopLoss,
      trailingStopLoss: data.trailingPrice,
    };
    json.scrips = json1;
    await commonService
      .addScripInBasket(json)
      .then(
        (resp) => {
          if (resp.data.status == "Ok" && resp.data.message == "Success") {
            dispatch("unSubsBasket");
            commit("setBasketScrips", resp.data?.result);
            dispatch("getBasketMargin");
          }
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {});
  },
  async updateScripInBasket({ state, commit, dispatch, rootState }, payload) {
    let data = payload;

    var info = rootState.orderWindow.selectedData;

    let json = { basketId: state.currentBasketData.basketId };
    let json1 = {
      exchange: info.exchange,
      token: info.token,
      tradingSymbol: info.tradingSymbol,
      qty: data.qty,
      id: info.id,
      price: data.priceType == "MKT" ? "0" : data.price,
      product: info.product,
      transType:
        info.transType == "BUY"
          ? "B"
          : info.transType == "SELL"
          ? "S"
          : info.transType,
      priceType: data.priceType,
      orderType: data.orderType,
      ret: data.validity ? data.validity : "DAY",
      triggerPrice:
        data.triggerPrice && Number(data.triggerPrice) != 0
          ? data.triggerPrice
          : "",
      disclosedQty:
        data.discQty && Number(data.discQty) != 0 ? data.discQty : "",
      mktProtection: data.MktProtection ? data.MktProtection : "",
      target:
        data.targetPrice && Number(data.targetPrice) != 0
          ? data.targetPrice
          : "",
      stopLoss:
        data.stopLoss && Number(data.stopLoss) != 0 ? data.stopLoss : "",
      trailingStopLoss:
        data.trailStopLoss && Number(data.trailStopLoss) != 0
          ? data.trailStopLoss
          : "",
      source: "WEB",
    };
    json.scrips = json1;

    await commonService
      .updateScrIpInBasket(json)
      .then(
        (resp) => {
          if (resp.data.status == "Ok" && resp.data.message == "Success") {
            dispatch("unSubsBasket");
            dispatch("getBasketScrips", {
              basketId: state.currentBasketData.basketId,
            });
            // commit("setBasketScrips", resp.data?.result);
            dispatch("getBasketMargin");
          }
        },
        (error) => {
          errorHandling.errLog(error);
        }
      )

      .finally(() => {});
  },
  async deleteScripInBasket({ state, commit, dispatch, rootGetters }, payload) {
    let json = {
      basketId: payload.basketId,
      scripsId: [payload.id],
    };
    await commonService.deleteScripInBasket(json).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          commit("setBasketScrips", resp.data?.result);
          commit("setIsConfirmDialog", false);
        }
        dispatch("getBasketMargin");
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },
  async refreshBasket({ state, commit, dispatch }, payload) {
    await commonService.refreshBasket(payload).then(
      (resp) => {
        let tempList = state.basketList;
        if (
          resp.status == 200 &&
          resp.data.status == "Ok" &&
          resp.data.result &&
          resp.data.message == "Success"
        ) {
          tempList = resp.data.result;

          let currentItem = resp.data.result.filter((resp) => {
            return resp.basketId == payload;
          });

          commit("setCurrentBasketData", currentItem[0]);
        } else {
          errorHandling.toaster(
            "Failed",
            "success",
            resp.data.message,
            4500,
            "top-right",
            " ",
            " "
          );
        }
        dispatch("setBasketList", tempList);
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },
  async setBasketList({ state, dispatch, rootGetters }, payload) {
    state.basketList = payload;
    dispatch(
      "order/setOrdersTabsCount",
      {
        open: rootGetters["order/getOpenOrders"],
        exec: rootGetters["order/getExcutedOrders"],
        basket: state.basketList,
        tradeBook: rootGetters["order/getTradeBook"],
        positions: rootGetters["positions/getPositionsData"],
      },
      { root: true }
    );
  },

  async subsBasket({ state, rootGetters, dispatch }, payload) {
    if (rootGetters["ws/getConnectionStatus"]) {
      let channel = "";
      state.basketScrips.forEach((el) => {
        channel += `${el["exchange"]}|${el["token"]}#`;
      });
      var tempData = {
        sub: channel,
        stage: "initial",
      };
      dispatch("ws/basketSubscription", tempData, { root: true });
    }
  },

  async unSubsBasket({ state, rootGetters, dispatch }) {
    if (rootGetters["ws/getConnectionStatus"]) {
      var tempData = {
        sub: rootGetters["ws/getBasketSubs"],
        stage: "esc",
      };
      dispatch("ws/basketSubscription", tempData, { root: true });
    }
  },

  async getBasketMargin({ state, commit, rootGetters, dispatch }) {
    let array = [];
    state.basketScrips.forEach((el) => {
      let json = {
        exchange: el.exchange,
        price: el.price,
        qty: el.qty,
        token: el.token,
        transType: el.transType == "BUY" ? "B" : "S",
      };
      array.push(json);
    });
    await commonService.getBasketMargin(array).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          commit("setBasketMargin", resp.data.result[0]?.span);
        } else {
          commit("setBasketMargin", 0);
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },

  async executeBasket({ state, commit, dispatch }, payload) {
    let json = {
      basketId: payload.basketId,
      scrips: [],
    };
    state.basketScrips.forEach((el) => {
      let j = {
        exchange: el.exchange,
        token: el.token,
        tradingSymbol: el.tradingSymbol,
        qty: el.qty,
        price: el.price,
        product: el.product,
        transType: el.transType == "BUY" ? "B" : "S",
        priceType: el.priceType,
        orderType: el.orderType,
        ret: el.ret,
        triggerPrice: el.triggerPrice,
        disclosedQty: el.disclosedQty,
        mktProtection: el.mktProtection,
        target: el.target,
        stopLoss: el.stopLoss,
        trailingStopLoss: el.trailingStopLoss,
        source: "WEB",
      };
      json.scrips.push(j);
    });
    await commonService.executeBasket(json).then(
      (resp) => {
        if (
          resp.status == 200 &&
          resp.data[0].status == "Ok" &&
          resp.data[0].message == "Basket exeuted successfully"
        ) {
          dispatch("getBasketList");
        } else {
          errorHandling.toaster(
            "",
            "danger",
            resp.data[0].message,
            3000,
            "top-right",
            " ",
            " "
          );
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },

  async resetBasketExeStatus({ state, commit, dispatch }, basketId) {
    await commonService.resetBasketExeStatus(basketId).then(
      (resp) => {
        if (resp.data.status == "Ok" && resp.data.message == "Success") {
          let currentItem = resp.data.result.filter((resp) => {
            return resp.basketId == basketId;
          });
          commit("setCurrentBasketData", currentItem[0]);
        }
      },
      (error) => {
        errorHandling.errLog(error);
      }
    );
  },
};

const mutations = {
  resetState(state) {
    state.basketList = [];
    state.basketScrips = [];
    state.currentBasketData = "";
    state.basketLoader = false;
    state.searchLoader = false;
    state.isBasket = false;
    state.basketMargin = "";
    state.basketOwType = "";
    state.basketCreateDialog = false;
    state.isBasketList = false;
    state.isRename = false;
    state.searchListForBasket = [];
    state.isConfirmDialog = false;
    state.isBasketEdit = false;
  },
  setBasketScrips(state, payload) {
    state.basketScrips = payload;
    this.dispatch("basket/subsBasket");
    this.dispatch("basket/checkWebsocketStatus");
  },
  setCurrentBasketData(state, payload) {
    state.currentBasketData = payload;
  },
  setLoader(state, payload) {
    state.basketLoader = payload;
  },
  setBasketNameUpdate(state, payload) {
    state.currentBasketData.basketName = payload;
  },
  setSearchLoader(state, payload) {
    state.searchLoader = payload;
  },
  setIsBasket(state, payload) {
    state.isBasket = payload;
  },
  setBasketMargin(state, payload) {
    state.basketMargin = parseFloat(payload).toFixed(2);
  },
  setBasketOwType(state, payload) {
    state.basketOwType = payload;
  },
  setBasketCreateDialog(state, payload) {
    state.basketCreateDialog = payload;
  },
  editBasketCondition(state, payload) {
    state.isBasketEdit = payload;
  },
  setIsBasketList(state, payload) {
    state.isBasketList = payload;
  },
  setIsRename(state, payload) {
    state.isRename = payload;
  },
  setSearchListForBasket(state, payload) {
    state.searchListForBasket = payload;
  },
  setIsConfirmDialog(state, payload) {
    state.isConfirmDialog = payload;
  },
  setBasketName(state, payload) {
    state.setBasketName = payload;
  },
};

const getters = {
  getBasketList: (state) => state.basketList,
  getcurrentBasketData: (state) => state.currentBasketData,
  getBasketScrips: (state) => state.basketScrips,
  getLoader: (state) => state.loader,
  getSLoader: (state) => state.searchLoader,
  getBasketMargin: (state) => state.basketMargin,
  getBasketOwType: (state) => state.basketOwType,
  getIsBasketDialog: (state) => state.isBasket,
  getBasketCreateDialog: (state) => state.basketCreateDialog,
  getIsBasketList: (state) => state.isBasketList,
  getIsRename: (state) => state.isRename,
  getSearchListForBasket: (state) => state.searchListForBasket,
  getIsConfirmDialog: (state) => state.isConfirmDialog,
  getBasketName: (state) => state.setBasketName,
};

const basket = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default basket;
