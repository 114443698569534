import store from "../store";
import formula from "../mixins/formula";
import chartBus from "@/views/chart/chartBus";
import { mapState } from "vuex";
import errorHandling from "../handleError/errHandling";
import router from "@/router";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("marketWatch", ["watchlistData"]),
  },
  methods: {
    getwindowheight(id, where) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = where == "table" ? 125 : where == "hold" ? 60 : 250;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    /***
     * @author senthil
     * @function method to change number as rupess format
     * @date 02-12-2021
     */
    validateAndFormatValue(data, key, parseFloat) {
      if (data) {
        if (data[key]?.toString()) {
          let value = Number(data[key]);
          if (isNaN(value)) {
            return "0.00";
          } else {
            return this.ruppesFormat(value.toFixed(parseFloat));
          }
        } else {
          return "0.00";
        }
      } else {
        return "0.00";
      }
    },

    ruppesFormat(num) {
      var n1, n2;

      num = num + "" || "";

      // works for integer and floating as well
      n1 = num.split(".");

      n2 = n1[1] || null;
      n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");

      num = n2 ? n1 + "." + n2 : n1;

      num = isNaN(parseFloat(num)) ? "0.00" : num;
      return num;
    },

    formatNum(value) {
      return !!value && value != "NA" ? value.replace(/,/g, "") : "0.00";
    },
    /***
     * @author senthil
     * @function method to download table in csv format
     * @date 21-12-2021
     */
    downloadCsv(page, data) {
      if (data.length != 0) {
        var html = document.getElementById("tableData").outerHTML;
        this.expoertToCsv(html, `${page}.csv`);
      }
    },
    /***
     * @author senthil
     * @function method to download table in csv format
     * @date 21-12-2021
     */
    expoertToCsv(html, filename) {
      var csv = [];
      var rows = document.querySelectorAll(".tableRow");
      for (var i = 0; i < rows?.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll(".tableContent, .tableHeader");
        for (var j = 0; j < cols?.length; j++)
          row.push('"' + cols[j].innerText + '"');
        csv.push(row.join(","));
      }
      // Download CSV
      this.download_csv(csv.join("\n"), filename);
    },
    /***
     * @author senthil
     * @function method to download table in csv format
     * @date 21-12-2021
     */
    download_csv(csv, filename) {
      var csvFile;
      var downloadLink;
      // CSV FILE
      csvFile = new Blob([csv], { type: "text/csv" });
      // Download link
      downloadLink = document.createElement("a");
      // File name
      downloadLink.download = filename;
      // We have to create a link to the file
      downloadLink.href = window.URL.createObjectURL(csvFile);
      // Make sure that the link is not displayed
      downloadLink.style.display = "none";
      // Add the link to your DOM
      document.body.appendChild(downloadLink);
      // Lanzamos
      downloadLink.click();
    },

    convertLacs(volume, AvgPrice) {
      if (volume && AvgPrice && volume != "NA" && AvgPrice != "NA") {
        var multipl =
          volume.toString().replaceAll(",", "") *
          AvgPrice.toString().replaceAll(",", "");
        var val = Math.abs(multipl);
        if (val >= 10000000) {
          val = (val / 10000000).toFixed(2) + " Cr";
        } else if (val >= 100000) {
          val = (val / 100000).toFixed(2) + " Lac";
        }

        return this.ruppesFormat(val);
      } else {
        return "0";
      }
    },

    /***
     * @author Bharath
     * @method Export Table to CSV File Using Json Array
     * @since 22/04/22
     **/
    checkDownload(fileName, page, value) {
      const modifyData = [];
      if (page == "orders") {
        var tableHead;
        fileName == "Executed"
          ? (tableHead = {
              Time: "",
              Type: "",
              Instrument: "",
              Product: "",
              Qty: "",
              Price: "",
              Status: "",
            })
          : fileName == "Trade Book"
          ? (tableHead = {
              Time: "",
              Type: "",
              Instrument: "",
              Product: "",
              Qty: "",
              Price: "",
            })
          : (tableHead = {
              Time: "",
              Type: "",
              Instrument: "",
              Product: "",
              Qty: "",
              LTP: "",
              Price: "",
              Status: "",
            });

        for (let item of value) {
          var price;
          if (fileName !== "Trade Book") {
            var tempPrice =
              item.exchange == "CDS" || item.exchange == "BCD"
                ? parseFloat(item.price).toFixed(4)
                : parseFloat(item.price).toFixed(2);
            var TriggerPrice =
              item.exchange == "CDS" || item.exchange == "BCD"
                ? parseFloat(item?.triggerPrice).toFixed(4)
                : parseFloat(item?.triggerPrice).toFixed(2);
            price =
              TriggerPrice > 0
                ? tempPrice + " / " + TriggerPrice + " trg"
                : tempPrice;
          }
          fileName == "Executed"
            ? (tableHead = {
                UserId: store.state.login.UCC,
                Time: item.orderTime,
                Type: item.transType == "B" ? "BUY" : "SELL",
                Instrument:
                  item.formattedInsName == null
                    ? item.tradingSymbol
                    : item.formattedInsName,
                Exchange: item.exchange,
                Product: item.product,
                OrderType: item.priceType,
                Qty: `'${item["fillShares"]} / ${item["qty"]}'`,
                Price: item.price,
                Status: item.orderStatus,
              })
            : fileName == "Trade Book"
            ? (tableHead = {
                UserId: store.state.authentication.userId,
                Time: item.orderTime,
                Type: item.transType == "B" ? "BUY" : "SELL",
                Instrument:
                  item.formatScripName == null
                    ? item.tradingSymbol
                    : item.formatScripName,
                Exchange: item.exchange,
                Product: item.product,
                OrderType: item.priceType,
                Qty: `'${item["Filledqty"] + " / " + item["Qty"]}'`,
                Price:
                  item.exchange == "CDS" || item.exchange == "BCD"
                    ? parseFloat(item.price).toFixed(4)
                    : parseFloat(item.price).toFixed(2),
                Status: item.orderStatus,
              })
            : (tableHead = {
                UserId: store.state.authentication.userId,
                Time: item.orderTime,
                Type: item.transType == "B" ? "BUY" : "SELL",
                Instrument:
                  item.formatScripName == null
                    ? item.tradingSymbol
                    : item.formatScripName,
                Exchange: item.exchange,
                Product: item.product,
                OrderType: item.priceType,
                Qty: `'${item["Fillshares"]} / ${item["Qty"]}'`,
                LTP:
                  item.ltp && (item.exchange == "CDS" || item.exchange == "BCD")
                    ? parseFloat(item.ltp).toFixed(4)
                    : item.ltp &&
                      item.exchange !== "CDS" &&
                      item.exchange !== "BCD"
                    ? parseFloat(item.ltp).toFixed(2)
                    : "",
                Price: price,
                Status: item.orderStatus,
              });

          // tableHead.Exchange_Order_Id = item.exchOrderId
          //   ? item.exchOrderId.toString()
          //   : "NA";
          tableHead.Order_No = item.orderNo ? item.orderNo.toString() : "NA";
          modifyData.push(tableHead);
        }
      } else if (page == "holdings") {
        for (let item of value) {
          var tableHead = {
            UserId: store.state.login.UCC,
            Instrument: item?.formatScripName
              ? item?.formatScripName
              : item?.tradingSymbol,
            "Qty.":
              parseInt(item.btstQty) == 0 ? item.sellableQty : `${item.btstQty} `,
            "Buy Price": item.buyPrice,
            LTP: item.ltp,
            PDC: item.pdc,
            "Buy Value": item.buyValue,
            "P&L": item.netPnl,
            "Net chg.": `${item.netChg}`,
          };
          modifyData.push(tableHead);
        }
        var footerHead = {
          UserId: "",
          Instrument: "Total Investment",
          "Qty.": '',
          "Buy Price": "Current Value",
          LTP: '',
          PDC: "Days P&L",
          "Buy Value": '',
          "P&L": "Net P&L",
          "Net chg.":'',
        };
        modifyData.push(footerHead);
        var footer = {
          UserId: "",
          Instrument: formula.methods.totalInvestment(value, "buyValue"),
          "Qty.": '',
          "Buy Price": formula.methods.totalCurrentValue(value, "currentVal"),
          LTP: '',
          PDC: `${formula.methods.totalDaysPnl(value)} ${formula.methods.totalDaysPnlChg(value,'dayChg','val')}`,
          "Buy Value": '',
          "P&L": `${formula.methods.totalNetPnl(value,'netPnl')} ${formula.methods.totalNetPnlChg(value,'show')}`,
          "Net chg.": '',
        };
        modifyData.push(footer);
      } else if (page == "gtt") {
        var tableHead = {
          Time: "",
          Type: "",
          Instrument: "",
          Price: "",
          "Trigger Price": "",
          Quantity: "",
        };

        for (let item of value) {
          tableHead = {
            UserId: store.state.login.UCC,
            Time: item.orderTime,
            Type: item.transType == "B" ? "BUY" : "SELL",
            Instrument:
              item.formattedInsName == null
                ? item.tradingSymbol
                : item.formattedInsName,
            Exchange: item.exchange,

            Qty: item.qty,
            Price: item.price,
          };
          modifyData.push(tableHead);
        }
      } else {
        for (let item of value) {
          var tableHead = {
            UserId: store.state.login.UCC,
            Type: item.product,
            Instrument: item.displayName,
            Exchange: item.exchange ? item.exchange : item?.exch,
            Qty: item.netQty,
            "Buy Avg": this.ruppesFormat(
              this.formateNumber(item?.buyPrice, item?.exchange)
            ),
            "Sell Avg": this.ruppesFormat(
              this.formateNumber(item?.sellPrice, item?.exchange)
            ),
            LTP:
              item.exchange == "CDS" || item.exchange == "BCD"
                ? parseFloat(item.ltp).toFixed(4)
                : parseFloat(item.ltp).toFixed(2),
            "Day's P&L": parseFloat(item.mtm).toFixed(2),
            "Net P&L": parseFloat(item.pnlNew).toFixed(2),
          };
          modifyData.push(tableHead);
        }
        var footer = {
          UserId: "",
          Type: "",
          Instrument: "",
          Exchange: "",
          Qty: "",
          "Buy Avg": "",
          "Sell Avg": "",
          LTP: "Total",
          "Day's P&L": `"${this.totalGroup(value, "mtm")}"`,
          "Net P&L": `"${this.totalGroup(value, "pnl")}"`,
        };
        modifyData.push(footer);
      }

      // get keys as array
      const keys = Object.keys(modifyData[0]);

      const commaSeparatedString = [
        keys.join(","),
        modifyData
          .map((row) => keys.map((key) => row[key]).join(","))
          .join("\n"),
      ].join("\n");

      const csvBlob = new Blob([commaSeparatedString]);

      const a2 =
        page == "orders"
          ? document.getElementById("a2")
          : page == "holdings"
          ? document.getElementById("hold")
          : page == "gtt"
          ? document.getElementById("gtt")
          : document.getElementById("position");

      var tempFileName =
        page == "orders" && fileName != "Trade Book"
          ? fileName + "-orders.csv"
          : fileName + ".csv";
      a2.download = tempFileName;
      a2.href = URL.createObjectURL(csvBlob);
    },

    async callTrandingViewChart(item, where) {
      if (
        !this.$store.state.symbolInfo.ticker ||
        (this.$store.state.symbolInfo.ticker &&
          this.$store.state.symbolInfo.ticker != item.token)
      ) {
        await this.$store.commit("setChartData", item);
        this.$router.currentRoute.path != "/chart"
          ? this.$router.push("/chart")
          : "";

        chartBus.methods.refresh(item);
      }
    },

    async callOrderWindowByContract(
      data,
      orderType,
      isModify,
      page,
      isBasket,
      isBasketModify,
      isRepeat,
      isGtt
    ) {
      await this.$store.dispatch("orderWindow/getContract", {
        data,
        orderType,
        isModify,
        page,
        isBasket,
        isBasketModify,
        isRepeat,
        isGtt,
      });
    },
    async callOptionChain(item) {
      if (item.expiry) {
        this.$store.commit("oc/setIsCallFromOut", true);
        let date = new Date(item.expiry);
        const options = {
          year: "2-digit",
          month: "short",
          day: "2-digit",
          timeZone: "UTC",
        };
        const dateFormatter = new Intl.DateTimeFormat("en-IN", options);
        const formattedDate = dateFormatter.format(date);
        let newExp = formattedDate
          ?.toUpperCase()
          ?.toString()
          ?.replace(/-/g, "");

        let ins = item.symbol
          ? item.symbol
          : item.tradingSymbol
          ? item.tradingSymbol
          : "";
        let exp = newExp;
        let exch = item.exchange;
        this.$store.commit("oc/setInsExp", { ins, exp, exch });
        if (this.$router.currentRoute.path != "/optionchain") {
          router.push("optionchain");
        } else {
          await this.$store
            .dispatch("oc/getUnderlying", { exch })
            .finally(() => {
              this.$store.dispatch("oc/getUnderlyingExp", {
                ins: ins,
                exch: item.exchange,
              });
              this.$store.commit("oc/setOcScrollCondition", true);
            });
        }
      }
    },
    totalGroup: function (group, key) {
      let total = [];
      if (group) {
        group.forEach((val) => {
          total.push(val[key]);
        });
        return this.ruppesFormat(
          parseFloat(
            total.reduce(function (total, num) {
              return parseFloat(total) + parseFloat(num);
            }, 0)
          ).toFixed(2)
        );
      }
    },

    timer(remaining) {
      var time;
      var m = Math.floor(remaining / 60);
      var s = remaining % 60;

      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      time = m + ":" + s;
      remaining -= 1;

      if (remaining >= 0) {
      } else {
        time = "Resend OTP";
      }
    },

    resetTimer() {
      clearTimeout(store.state.authentication.restTimer);
    },
    navigateOption(val, key) {
      //
      if (key == "funda" && val.formatScripName) {
        let scrip = val.formatScripName.includes("-")
          ? val.formatScripName.split("-")[0]
          : val.formatScripName;
        this.$store.commit("setisTechnical", true);
        this.$store.commit("setTechnicalUrl", ``);
      }

      if (key == "tech" && val.formatScripName) {
        let scrip = val.formatScripName.includes("-")
          ? val.formatScripName.split("-")[0]
          : val.formatScripName;
        this.$store.commit("setisTechnical", true);
        this.$store.commit("setTechnicalUrl", `}`);
      }
    },

    //   css Theme
    menuColors() {
      return this.$vuetify.theme.dark
        ? "darkUnactive white--text"
        : "unActiveColor primaryColor";
    },
    homeMenuColors(active) {
      if (active) {
        return this.$vuetify.theme.dark
          ? "activeColor white--text"
          : "bg-color-aliceblue fcolor-1B71C2 font-weight-bold";
      } else {
        return this.$vuetify.theme.dark
          ? "unActiveColor primaryColor"
          : "white black--text";
      }
    },
    homeEquityMenuColors(active) {
      if (active) {
        return this.$vuetify.theme.dark
          ? "activeColor white--text"
          : "bg-color-aliceblue fcolor-1B71C2 font-weight-bold";
      } else {
        return this.$vuetify.theme.dark
          ? "unActiveColor color-282828"
          : "white black--text";
      }
    },
    callAuthMethod(where, key) {
      let temp = {};
      if (where == "BOT") {
        temp.appcode = this.$store.state.botAppCode;
        temp.loder =
          this.$router.currentRoute.path == "/holdings" ||
          this.$router.currentRoute.path == "/funds"
            ? "dfgdfsh"
            : where;
        this.$store.dispatch("login/validateVendor", temp);
      }
      if (where == "mutual") {
        temp.appcode = this.$store.state.mutualAppcode;
        temp.loder = where;
        this.$store.dispatch("login/validateVendor", temp);
      }
      if (where == "ipoAppCode") {
        temp.appcode = this.$store.state.ipoAppCode;
        temp.loder = where;
        this.$store.dispatch("login/validateVendor", temp);
      }
      this.$store.commit("setValidateAuthCode", temp.appcode);
    },

    // lotsize changes for MCX
    chgQty(item) {
      if (item.exchange == "MCX" && item.lotSize) {
        var qty = item.qty / item.lotSize;
      } else {
        var qty = item.qty;
      }
      return qty;
    },

    /**
     * Method to formate number based on exchange
     * @author Sivaselvan
     * @returns Formated Value
     */
    formateNumber(formateVal, exch) {
      var formatedValue = Number(formateVal);
      if (exch == "CDS" || exch == "BCD") {
        var data = formatedValue ? formatedValue.toFixed(4) : "0.0000";
      } else {
        var data = formatedValue ? formatedValue.toFixed(2) : "0.00";
      }
      return data;
    },

    //Calculate total mtm and netpnl
    totalGroup: function (group, type) {
      let total = [];
      if (group) {
        if (type == "pnlNew") {
          total = [];
          group.forEach((val) => {
            total.push(val.pnlNew);
          });
        } else if (type == "mtm") {
          total = [];
          group.forEach((val) => {
            total.push(val.mtm);
          });
        }
        var value = this.ruppesFormat(
          parseFloat(
            total.reduce(function (total, num) {
              return parseFloat(total) + parseFloat(num);
            }, 0)
          ).toFixed(2)
        );
        return formula.methods.nullUndefined(parseFloat(value).toFixed(2))
          ? value
          : "0.00";
      }
    },

    isNullOrUndef(data) {
      if (data) {
        return true;
      } else {
        false;
      }
    },
    removeComma(value) {
      let v = value?.toString().replace(/,/g, ",");
      return parseFloat(v);
    },

    /**
     * Method to add scrip in Current watchlist
     * @since 04/03/2024
     */
    async addMwScript(item) {
      var token = [];
      var isAddItem;
      this.watchlistData.forEach((element) => {
        token.push(element.token);
      });
      if (this.watchlistData.length == 0) {
        isAddItem = true;
      } else {
        if (!token.includes(item.token)) {
          isAddItem = true;
        } else {
          isAddItem = false;
        }
      }
      if (isAddItem == true) {
        let json = {
          scripData: [
            {
              exch: item.exchange,
              token: item.token,
            },
          ],
        };
        if(this.watchlistData && this.watchlistData.length < 30){
         await this.$store.dispatch("marketWatch/addSearchList", { json, item, isToaster: true });
        this.$store.dispatch("marketWatch/changeWatchList").finally(() => {
          if (
            window.addwatch &&
            typeof window.addwatch?.callback === "function"
          ) {
            window.addwatch.callback();
          }
        });
        }else{
          errorHandling.toaster(
            "",
            "danger",
            "Can't add more than 30 scrips.Use another tab",
            4500,
            "top-right",
            " ",
            " "
          );
        }
        
      } else {
        errorHandling.toaster(
          "",
          "danger",
          "Scrip already available in current market watch",
          4500,
          "top-right",
          " ",
          " "
        );
      }
    },
    getFontSize(stage) {
      return this.returnFontSize(this.$store.state.fontSize, stage);
    },
    returnFontSize(size, value) {
      let str = "fsize";
      if (size == "s") {
        let op = str + value.toString();
        return op;
      } else if (size == "m") {
        let val = value + 2;
        let op = str + val.toString();
        return op;
      } else if (size == "l") {
        let val = value + 4;
        let op = str + val.toString();
        return op;
      }
      // else if (size == "xl") {
      //   let val = value + 6;
      //   let op = str + val.toString();
      //   return op;
      // }
    },
  },
};
