<template>
  <div
    style="height: 100%"
    ref="TVChartContainer"
    v-if="$store.state.chart.chartUrl"
  >
    <iframe
      class="rounded"
      id="chartFrame"
      :src="$store.state.chart.chartUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>
<script>
import chartBus from "./chartBus";
import commonChart from "./commonChart";
export default {
  name: "chart",
  mixins: [chartBus, commonChart],
  methods: {
    wsChart() {
      let tickerData = window.tickers;
      let stream = document.getElementById("chartFrame");

      if (
        tickerData &&
        stream &&
        stream.contentWindow &&
        stream.contentWindow
      ) {
        let message = {
          id: "stream",
          message: tickerData,
        };

        stream.contentWindow?.postMessage(message, "*");
      }
    },
  },
  mounted() {
    window.chart = {
      callback: this.wsChart,
    };
  },
  async created() {
    let getLocal = await JSON.parse(localStorage.getItem("chartData"));

    if (getLocal) {
      this.$store.commit("chart/setChartData", getLocal);
      this.refresh(getLocal);
    }
    this.$store.dispatch("wsConnection/websocketSubscription", {
      data: [],
      where: "chart",
    });
  },

  destroyed() {
    delete window.chart;
  },
};
</script>
