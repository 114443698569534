<template>
  <div
    class="noData-height d-flex flex-column align-center justify-center items-center"
    id="mw_no_data_div"
  >
    <img
      width="20%"
      src="../assets/images/no-data-giga.svg"
      id="mw_no_data_img"
      alt="noDataImg"
      class="mb-2"
    />
    {{ `No ${noDataValue} Found` }}
  </div>
</template>
<script>
export default {
  name: "noData",
  props: {
    noDataValue: String,
  },
};
</script>
<style>
.noData-height {
  min-height: calc(100vh - 168px);
}
</style>
