import { commonService } from "../../services/common.service";
import errorHandling from "../../handleError/errHandling";

const state = {
  alertData: {},
  alertCallSource: "",
  ordersLoader: false,
  openAlertDialog: false,
  ordersData: [],
  openOrders: [],
  excutedOrders: [],
  orderBookTabs: [
    { name: "Positions", id: 0, active: true, count: "0" },
    { name: "Pending", id: 1, active: true, count: "0" },
    { name: "Executed", id: 2, active: false, count: "0" },
    { name: "Tradebook", id: 3, active: false, count: "0" },
    { name: "Basket", id: 4, active: false, count: "0" },
    { name: "Alerts", id: 5, active: false, count: "0" },
  ],
  infoTabs: [
    { name: "Information", id: 0, active: true, count: "0" },
    { name: "History", id: 1, active: false, count: "0" },
  ],
  tradeBook: [],
  objectOrdersList: "",
  orderHistory: [],
  stringOrderList: "",
  basketOrderList: [],
  cancelDialog: false,
  infoDialog: false,
  cancelLoader: false
};

const actions = {
  async setOrders({ state, commit, dispatch, rootState }, payload) {
    state.ordersData = payload;
    //
    let openOrders = [];
    let execOrders = [];

    let tickers = window.tickers;
    let i = 0;
    payload?.forEach((el) => {
      el.wsToken = `${el.exchange}|${el.token}`;

      if (el.exchange == "MCX") {
        el.qty = (Number(el.qty) / Number(el.lotSize)).toString();
      }

      let stat = el?.orderStatus?.toLowerCase();
      if (stat == "open" || stat == "trigger_pending") {
        el.ltp = '';
        openOrders.push(el);

        i += 1;
      }
      if (stat == "rejected" || stat == "complete" || stat == "canceled") {
        execOrders.push(el);
      }
      if (tickers && stat == "open") {
        el.ltp = tickers[el.wsToken]?.lp ? tickers[el.wsToken]?.lp : el.ltp;
      }
    });

    commit("setStringOrderList", openOrders);
    commit("setOpenOrders", openOrders);
    commit("setExcutedOrders", execOrders);
    dispatch("setOrdersTabsCount", {
      open: state?.openOrders,
      exec: state?.excutedOrders,
      tradeBook: state?.tradeBook,
    });
    let arr = rootState.orderTabList;

    arr.forEach((el) => {
      if (el.name == "Pending") {
        el.value = el.name;
        el.value =
          openOrders?.length > 0
            ? el.value + " " + `(${openOrders.length})`
            : el.value;
      } else if (el.name == "Executed") {
        el.value = "Order Book";
        el.value =
          execOrders?.length > 0
            ? el.value + " " + `(${execOrders.length})`
            : el.value;
      }
    });
  },
  async getUserOrders({ state, commit, dispatch, rootGetters }) {
    commit("setLoader", true);
    await commonService
      .getOrderBook()
      .then(
        (response) => {
          if (
            response.data.message == "Success" &&
            response.data.status == "Ok" &&
            response?.data?.result
          ) {
            dispatch("setOrders", response?.data?.result);
          } else {
            dispatch("setOrders", []);
          }
        },
        (err) => {
          commit("setLoader", false);
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },


  async getTradeBook(
    { state, commit, dispatch, rootGetters, rootState },
    payload
  ) {
    commit("setLoader", true);
    let json = {
      userId: rootGetters["login/getUCC"],
      actId: rootGetters["login/getUCC"],
    };
    await commonService
      .getTradeBook(json)
      .then(
        (resp) => {
          if (resp.status == 200 && resp.data.status == "Ok") {
            commit("setTradeBook", resp.data?.result);

            let arr = rootState.orderTabList;
            arr.forEach((el) => {
              if (el.name == "Tradebook") {
               el.value = "Trade Book";
                el.value =
                  resp.data.result?.length > 0
                    ? el.value + " " + `(${resp.data?.result.length})`
                    : el.value;
              }
            });
          } else {
            commit("setTradeBook", []);
          }
        },
        (err) => {
          commit("setLoader", false);
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },

  async setOrdersTabsCount({ state, rootGetters }, payload) {
    state.orderBookTabs.forEach((el) => {
      if (el.name == "Pending") {
        el.count = payload.open.length == 0 ? "" : `(${payload.open.length})`;
      }
      if (el.name == "Executed") {
        el.count = payload.exec.length == 0 ? "" : `(${payload.exec.length})`;
      }
      if (el.name == "Tradebook") {
        el.count =
          payload.tradeBook?.length == 0
            ? ""
            : `(${payload.tradeBook?.length})`;
      }
      if (el.name == "Alerts" && payload.alerts) {
        el.count =
          payload.alerts?.length == 0 ? "" : `(${payload.alerts?.length})`;
      }
      if (el.name == "Basket" && payload.basket) {
        el.count =
          payload.basket?.length == 0 ? "" : `(${payload.basket?.length})`;
      }
      if (el.name == "Positions" && payload.positions) {
        el.count =
          payload.positions?.length == 0
            ? ""
            : `(${payload.positions?.length})`;
      }
    });
  },

  async getOrderHistory({ state, commit, rootGetters }, payload) {
    let json = {
      userId: rootGetters["login/getUCC"],
      orderNo: payload.orderNo,
    };
    await commonService.getOrderHistory(json).then(
      (resp) => {
        if (
          resp.status == 200 &&
          resp.data.status == "Ok" &&
          resp.data.result
        ) {
          commit("setOrderHistory", resp.data.result);
        } else {
          commit("setOrderHistory", []);
        }
      },
      (err) => {
        errorHandling.errLog(err);
      }
    );
  },

  checkWebsocketStatus({ state, dispatch, rootGetters }) {
    if (rootGetters["wsConnection/getConnectionStatue"]) {
      var tempData = {
        data: state.openOrders,
        where: "orders",
      };
      dispatch("wsConnection/websocketSubscription", tempData, { root: true });
    }
  },

  async cancelOrder({ state, commit, dispatch, rootGetters }, payload) {
    commit('setCancelLoader', true)
    await commonService[payload.key == 'bo_co' ? 'exitBoCoPosition' : 'cancelOrder'](payload.data).then(
      (response) => {
        if (response.status == 200 && response.data && response.data.length > 0) {
          for(let item of response.data){
            if(item.status && item.status == 'Not ok'){
              errorHandling.toaster(
                "",
                "danger",
                item.message,
                3000,
                "top-right",
                " ",
                " "
              );
            }
          }

          
        } 
      },
      (err) => {
        errorHandling.errLog(err);
      }
    ).finally(()=>{
      commit('setCancelLoader', false)
      commit('setCancelDialog', false)
    });
  },
};

const mutations = {
  resetState(state) {
    state.alertData = {};
    state.alertCallSource = "";
    state.ordersLoader = false;
    state.openAlertDialog = false;
    state.ordersData = [];
    state.openOrders = [];
    state.excutedOrders = [];
    state.orderBookTabs = [
      { name: "Positions", id: 0, active: true, count: "0" },
      { name: "Pending", id: 1, active: true, count: "0" },
      { name: "Executed", id: 2, active: false, count: "0" },
      { name: "Tradebook", id: 3, active: false, count: "0" },
      { name: "Basket", id: 4, active: false, count: "0" },
      { name: "Alerts", id: 5, active: false, count: "0" },
    ];
    state.infoTabs = [
      { name: "Information", id: 0, active: true, count: "0" },
      { name: "History", id: 1, active: false, count: "0" },
    ];
    state.tradeBook = [];
    state.objectOrdersList = "";
    state.orderHistory = [];
    state.stringOrderList = "";
    state.basketOrderList = [];
    state.cancelDialog = false;
    state.infoDialog = false;
  },
  setLoader(state, payload) {
    state.ordersLoader = payload;
  },
  openAlertDialog(state, payload) {
    state.openAlertDialog = payload.openCond;
    state.alertCallSource = payload.alertCallSource
      ? payload.alertCallSource
      : "";

    state.alertData = payload.props ? payload.props : {};
  },

  setOpenOrders(state, payload) {
    state.openOrders = payload;
    this.dispatch("order/checkWebsocketStatus");
  },

  setExcutedOrders(state, payload) {
    state.excutedOrders = payload;
  },

  setOrdersTabs(state, payload) {
    state.orderBookTabs = payload;
  },

  setTradeBook(state, payload) {
    state.tradeBook = payload;
  },

  setOrderHistory(state, payload) {
    state.orderHistory = payload;
  },

  setStringOrderList(state, payload) {
    state.stringOrderList = payload;
  },

  setBasketList(state, payload) {
    state.basketOrderList = payload;
  },

  setAlertDetails(state, payload) {
    state.alertDetails = payload;
  },

  setCancelDialog(state, payload) {
    state.cancelDialog = payload;
  },
  setInfoDialog(state, payload) {
    state.infoDialog = payload;
  },
  setCancelLoader(state, payload){
    state.cancelLoader = payload
  }
};

const getters = {
  getOrdersData: (state) => state.ordersData,
  getOpenOrders: (state) => state.openOrders,
  getExcutedOrders: (state) => state.excutedOrders,
  getOrderBookTabs: (state) => state.orderBookTabs,
  getTradeBook: (state) => state.tradeBook,
  getObjectOrdersList: (state) => state.objectOrdersList,
  getOrderHistory: (state) => state.orderHistory,
  getInfoTabs: (state) => state.infoTabs,
  getOpenAlertDialogValue: (state) => state.openAlertDialog,
  getBasketOrderList: (state) => state.basketOrderList,
  getCancelDialog: (state) => state.cancelDialog,
  getInfoDialog: (state) => state.infoDialog,
};

const orders = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default orders;
