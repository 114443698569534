import { commonService } from "@/services/common.service";
import commonFunctions from "@/mixins/commonFunctions";
import router from "@/router";
import errorHandling from "@/handleError/errHandling";
function findNearest(arr, num) {
  let nearest = null;
  let minDiff = Infinity;

  for (let i = 0; i < arr.length; i++) {
    const diff = Math.abs(Number(num) - Number(arr[i].strikeprice));

    if (diff < minDiff) {
      minDiff = diff;
      nearest = Number(arr[i].strikeprice);
    }
  }

  return nearest;
}
function gvalCalcset() {
  state.sco = 0;
  state.bco = 0;
  state.spo = 0;
  state.bpo = 0;

  state.optionChainData.forEach((el) => {
    var coi = Number(el.CE?.oi);
    state.sco += coi;
    if (state.bco < coi) {
      state.bco = coi;
    }

    var poi = Number(el.PE?.oi);
    state.spo += poi;
    if (state.bpo < poi) {
      state.bpo = poi;
    }
  });
  state.optionChainData.forEach((el) => {
    el.CE.ceGval = calcCeGval(Number(el.CE?.oi));
    el.PE.peGval = calcPeGval(Number(el.PE?.oi));
  });
}
function calcCeGval(oi) {
  let mf = 0;
  let gval = 0;
  let currentOi = parseFloat(oi);
  mf = 80 / (state.bco / state.sco);
  gval = (currentOi / state.sco) * mf;
  return gval;
}
function calcPeGval(oi) {
  let mf = 0;
  let gval = 0;
  let currentOi = parseFloat(oi);
  mf = 80 / (state.bpo / state.spo);
  gval = (currentOi / state.spo) * mf;
  return gval;
}
const state = {
  optionChainData: [],
  ocSymbol: "",
  ocExpiry: "",
  expiryList: [],
  underlyingList: [],
  exchangeList: ["NFO", "BFO", "MCX", "CDS"],
  exchangeKey: [
    { name: "NFO", key: "nse_fo" },
    { name: "BFO", key: "bse_fo" },
    { name: "MCX", key: "mcx_fo" },
    { name: "CDS", key: "cde_fo" },
  ],
  nearestStrike: 0,
  spotToken: "",
  spotPosition: "",
  ocdFull: [],
  ocList: [],
  sco: 0,
  bco: 0,
  spo: 0,
  bpo: 0,
  futToken: "",
  ocLoader: false,
  ocExchange: "NFO",
  ocScrollCondition: false,
  isCallFromOut: false,
  isRefresh: false,
};
const mutations = {
  setOcLoader(state, payload) {
    state.ocLoader = payload;
  },
  setFutToken(state, payload) {
    state.futToken = payload;
  },
  setOCDFull(state, payload) {
    state.ocdFull = payload;

    state.ocdFull.futLTPCP = "0.00";
    state.ocdFull.spotLTP = "0.00";
  },
  setNearestStrike(state, payload) {
    state.nearestStrike = findNearest(state.optionChainData, payload);

    state.spotPosition =
      state.nearestStrike > state.ocdFull.spotLTP ? "top" : "bottom";
  },
  setOptionChainData(state, payload) {
    state.optionChainData = payload;
    gvalCalcset();
  },
  setOcSocket(state, payload) {
    state.ocList = payload;

    this.dispatch("oc/checkWebsocketStatus");
  },
  setInsExp(state, payload) {
    payload?.ins ? (state.ocSymbol = payload?.ins) : "";
    payload?.exp ? (state.ocExpiry = payload?.exp) : "";
    payload?.exch ? (state.ocExchange = payload?.exch) : "";
    payload?.ins ? localStorage.setItem("ins", payload?.ins) : "";
    payload?.exp ? localStorage.setItem("exp", payload.exp) : "";
    payload?.exch ? localStorage.setItem("exch", payload.exch) : "";
  },
  setExpiryList(state, payload) {
    state.expiryList = payload;
  },
  setUnderlyingList(state, payload) {
    state.underlyingList = payload;
    // state.ocSymbol = payload[0];
  },
  setSpotToken(state, payload) {
    state.spotToken = payload;
  },
  setOcScrollCondition(state, payload) {
    state.ocScrollCondition = payload;
  },
  setIsCallFromOut(state, payload) {
    state.isCallFromOut = payload;
  },
  setIsRefresh(state, payload) {
    state.isRefresh = payload;
  },
};

const actions = {
  checkWebsocketStatus({ state, dispatch, rootGetters }) {
    if (rootGetters["wsConnection/getConnectionStatue"]) {
      var tempData = {
        data: state.ocList,
        where: "optionChain",
      };

      dispatch("wsConnection/websocketSubscription", tempData, { root: true });
    }
  },
  async getUnderlying({ state, commit }, payload) {
    commit("setOcLoader", true);
    let exchange = "";
    state.exchangeKey.forEach((el) => {
      return payload.exch == el.name ? (exchange = el.key) : "";
    });
    let json = {
      exch: exchange,
    };

    await commonService.getUnderlying(json).then(
      (res) => {
        if (
          res.status == 200 &&
          res.data?.result &&
          res.data.result[0].list_underlying
        ) {
          commit("setUnderlyingList", res.data.result[0].list_underlying);
          router.currentRoute.path == "/optionchain" || !state.isRefresh
            ? commit("setInsExp", {
                ins:
                  res.data.result[0].list_underlying?.length > 0
                    ? res.data.result[0].list_underlying[0]
                    : "",
              })
            : "";
        }
      },
      (err) => {
        errorHandling.errLog(err);
        commit("setOcLoader", false);
      }
    );
  },
  async getUnderlyingExp({ state, commit, dispatch }, payload) {
    commit("setOcLoader", true);
    let exchange = "";
    state.exchangeKey.forEach((el) => {
      return el.name == payload.exch ? (exchange = el.key) : "";
    });
    const json = {
      underlying: payload.ins,
      exch: exchange,
    };
    await commonService.getUnderlyingExp(json).then(
      (res) => {
        if (
          res.status == 200 &&
          res.data.result &&
          res.data.result.length > 0 &&
          res.data.result[0].underlying_expiry &&
          res.data.result[0].underlying_expiry.length > 0
        ) {
          commit("setExpiryList", res.data.result[0].underlying_expiry);

          router.currentRoute.path == "/optionchain" || !state.isRefresh
            ? commit("setInsExp", {
                ins: payload.ins,
                exp:
                  res.data.result[0].underlying_expiry?.length > 0
                    ? res.data.result[0].underlying_expiry[0]
                    : "",
              })
            : "";
          if (res.data.result[0].underlying_expiry?.length) {
            let json = {
              expiry: res.data.result[0].underlying_expiry[0],
              interval: 10,
              underlying: payload.ins,
              exch: payload.exch,
            };
            dispatch("getOptionChain", json);
          }
        }
      },
      (err) => {
        errorHandling.errLog(err);
        commit("setOcLoader", false);
      }
    );
  },
  async getOptionChain({ state, commit, dispatch }, payload) {
    commit("setOcLoader", true);
    let exchange = "";
    state.exchangeKey.forEach((el) => {
      return payload.exch == el.name ? (exchange = el.key) : "";
    });
    payload.exch = exchange;
    await commonService
      .getOptionChain(payload)
      .then(
        async (res) => {
          if (
            res.status == 200 &&
            res.data.result &&
            res.data.result.length > 0 &&
            res.data.result[0].data &&
            res.data.result[0].data.length > 0
          ) {
            let arr = res.data.result[0].data;

            let ceList = [];
            let peList = [];

            arr.filter((el) => {
              if (el.CE) {
                el.CE.ltpPer = "0.00";
                el.CE.exchange = state.ocExchange;
                ceList.push(el.CE);
              }
              if (el.PE) {
                el.PE.exchange = state.ocExchange;
                el.PE.ltpPer = "0.00";
                peList.push(el.PE);
              }
            });
            let ex =
              state.ocExchange == "NFO"
                ? "NSE"
                : state.ocExchange == "BFO"
                ? "BSE"
                : state.ocExchange;
            let ocExpiry = [
              { exchange: ex, token: res.data.result[0].spotToken },
            ];
            let ocList = [...ceList, ...peList, ...ocExpiry];

            await commit("setOptionChainData", arr);
            await commit("setFutToken", res.data.result[0].futToken);
            await commit("setSpotToken", res.data.result[0].spotToken);

            await commit("setNearestStrike", res.data.result[0].futLTP);
            await commit("setOCDFull", res.data.result[0]);
            commit("setOcSocket", ocList);
          } else {
            await commit("setOptionChainData", []);
            await commit("setFutToken", "");
            await commit("setSpotToken", "");

            await commit("setNearestStrike", "");
            await commit("setOCDFull", []);
            commit("setOcSocket", []);
          }
        },
        (err) => {
          errorHandling.errLog(err);
          commit("setOcLoader", false);
        }
      )
      .finally(() => {
        commit("setOcLoader", false);
      });
  },
};
const getters = {
  ocList: (state) => state.ocList,
};
const oc = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
export default oc;
