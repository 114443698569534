import { commonService } from "../../services/common.service";
import errorHandling from "../../handleError/errHandling";
import mtom from "../../mixins/formula";
import common from "../Services/common";

const position = {
  namespaced: true,

  state: {
    positionList: [],
    fnoList: [],
    mcxList: [],
    currentTableData: [],
    totalPnl: 0,
    nfoTotalPnl: 0,
    mcxTotalPnl: 0,
    currentTotalPnl: 0,
    tabList: [],
    postionLoader: false,
    positionConvertData: [],
    positionExitDialog: false,
    positionConvertDialog: false,
    exitLoader: false,
    convertionLoader: false,
    infoType: 0,
    selectedCheckBox: [],
    enableListLength: "",
    positionTab: "All",
    showExitDialog: false,
    showConvertDialog: false,
    showInfoDialog: false,
    authorizeList: [],
    revocationList: [],
    squareOffArray: [],
    exitAll: false
  },

  mutations: {
    setSquareOffArray(state, payload) {
      state.squareOffArray = payload;
    },
    handleInfoDialog(state, payload) {
      state.showInfoDialog = payload;
    },
    handleConvertDialog(state, payload) {
      state.showConvertDialog = payload;
    },
    handleExitDialog(state, payload) {
      state.showExitDialog = payload;
    },
    resetState(state) {
      state.positionList = [];
      state.fnoList = [];
      state.mcxList = [];
      state.currentTableData = [];
      state.totalPnl = 0;
      state.nfoTotalPnl = 0;
      state.mcxTotalPnl = 0;
      state.currentTotalPnl = 0;
      state.tabList = [];
      state.postionLoader = false;
      state.positionConvertData = [];
      state.positionExitDialog = false;
      state.positionConvertDialog = false;
      state.exitLoader = false;
      state.convertionLoader = false;
      state.infoType = 0;
      state.selectedCheckBox = [];
      state.enableListLength = "";
      state.positionTab = "All";
      state.showExitDialog = false;
      state.showConvertDialog = false;
      state.showInfoDialog = false;
      state.authorizeList = [];
      state.revocationList = [];
      state.squareOffArray = [];
    },

    setCurrentTableData(state, type) {
      type == "F&O"
        ? (state.currentTableData = state.fnoList ? state.fnoList : [])
        : type == "MCX"
        ? (state.currentTableData = state.mcxList ? state.mcxList : [])
        : (state.currentTableData = state.positionList
            ? state.positionList
            : []);
    },

    setTabList(state) {
      state.tabList = [];
      var Items = ["All", "F&O", "MCX"];
      for (let item of Items) {
        var tempData = {
          name: item,
          length:
            item == "All"
              ? state.positionList?.length
              : item == "F&O"
              ? state.fnoList?.length
              : item == "MCX"
              ? state.mcxList?.length
              : "",
          toolTip:
            item == "All"
              ? "All Positions"
              : item == "F&O"
              ? "Futures and Options"
              : item == "MCX"
              ? "Commodity"
              : "",
        };
        state.tabList.push(tempData);
      }
    },
    setPositionConvertData(state, payload) {
      state.positionConvertData = payload;
    },
    setPositionExitDialog(state, payload) {
      state.positionExitDialog = payload;
    },
    setPositionConvertDialog(state, payload) {
      state.positionConvertDialog = payload;
    },
    setCurrentTab(state, payload) {
      state.infoType = payload;
    },
    setResetCheckBox(state, payload) {
      state.selectedCheckBox = payload;
    },
    setPositionTab(state, payload) {
      state.positionTab = payload;
    },
    setExitAll(state, payload){
      state.exitAll = payload
    }
  },

  actions: {
    setPositions({ state, commit, dispatch, rootState }, payload) {
      try {
        if (payload) {
          var QtyfnoArr = [];
          var zerofnoArr = [];
          var QtymcxArr = [];
          var zeromcxArr = [];
          var QtyTempArr = [];
          var zeroTempArr = [];
          var positionObj = {};
          var enableList = [];
          var sqrOffArray = [];
          payload.forEach((el) => {
            //remove comma for TR prices
            el.buyPrice = el.buyPrice.replace(",", "");
            el.sellPrice = el.sellPrice.replace(",", "");
            el.mtmBuyPrice = el.mtmBuyPrice.replace(",", "");
            el.mtmSellprice = el.mtmSellprice.replace(",", "");

            if (el.exchange == "MCX") {
              el.netQty = mtom.methods.getLotSize(
                el.lotsize,
                el.netQty,
                el.exchange
              );
              el.buyQty = mtom.methods.getLotSize(
                el.lotsize,
                el.buyQty,
                el.exchange
              );
              el.sellQty = mtom.methods.getLotSize(
                el.lotsize,
                el.sellQty,
                el.exchange
              );
            }
            el.netQty = String(Number(el.netQty));
            el.pnl =
              Number(mtom.methods.realisedProfitLoss(el, "pnl")) +
              Number(mtom.methods.unRealisedProfitLoss(el, "pnl"));
            el.pnl = parseFloat(el.pnl).toFixed(2);
            el.unrealizedpnlNew = mtom.methods.unRealisedProfitLoss(el, "pnl");
            el.realizedPnl = mtom.methods.realisedProfitLoss(el, "pnl");
            el.pnlNew =
              Number(mtom.methods.realisedProfitLoss(el, "pnl")) +
              Number(mtom.methods.unRealisedProfitLoss(el, "pnl"));

            el.mtm =
              Number(mtom.methods.realisedProfitLoss(el, "mtm")) +
              Number(mtom.methods.unRealisedProfitLoss(el, "mtm"));
            positionObj[el.token] = el;

            el.type =
              mtom.methods.nullUndefined(el.orderType) == "Cover"
                ? "CO"
                : mtom.methods.nullUndefined(el.orderType) == "Bracket"
                ? "BO"
                : el.product;

            if (el.netQty > 0 && el.type != "BO" && el.type != "CO") {
              enableList.push(el);
            }

            if (parseFloat(el.netQty) == 0) {
              zeroTempArr.push(el);
            } else {
              QtyTempArr.push(el);
            }
            if (el.exchange == "NFO") {
              if (parseFloat(el.netQty) == 0) {
                zerofnoArr.push(el);
              } else {
                QtyfnoArr.push(el);
              }
            }
            if (el.exchange == "MCX") {
              if (parseFloat(el.netQty) == 0) {
                zeromcxArr.push(el);
              } else {
                QtymcxArr.push(el);
              }
            }
            if (
              el.netQty != "0" &&
              el.orderType != "Bracket" &&
              el.orderType != "Cover"
            ) {
              sqrOffArray.push(el);
            }
          });
        }
        commit("setSquareOffArray", sqrOffArray);
        state.fnoList =
          zerofnoArr.length == 0 ? QtyfnoArr : QtyfnoArr.concat(zerofnoArr);
        state.mcxList =
          zeromcxArr.length == 0 ? QtymcxArr : QtymcxArr.concat(zeromcxArr);
        state.positionList =
          zeroTempArr.length == 0 ? QtyTempArr : QtyTempArr.concat(zeroTempArr);
        state.enableListLength = enableList.length;
        commit("setCurrentTableData", state.positionTab);

        dispatch("checkWebsocketStatus");
        let arr = rootState.positionTabList;
        arr.forEach((el) => {
          el.value = el.name;
          if (el.name == "All") {
            let sum = zeroTempArr.length + QtyTempArr.length;
            el.value = sum > 0 ? el.value + " " + `(${sum})` : el.name;
          } else if (el.name == "F&O") {
            let sum = zerofnoArr.length + QtyfnoArr.length;
            el.value = sum > 0 ? el.value + " " + `(${sum})` : el.name;
          } else if (el.name == "MCX") {
            let sum = zeromcxArr.length + QtymcxArr.length;
            el.value = sum > 0 ? el.value + " " + `(${sum})` : el.name;
          }
        });
      } catch (error) {}
    },
    async productConversion({ commit, dispatch }, payload) {
      let json = {
        exchange: payload.exchange,

        tradingSymbol: payload.tradingsymbol,
        qty: Number(
          payload.exchange == "MCX"
            ? Math.abs(payload.convertQty * payload.lotsize)
            : Math.abs(payload.convertQty)
        ),
        prevProduct: payload.product,
        product:
          payload.exchange == "NSE" || payload.exchange == "BSE"
            ? payload.product == "CNC"
              ? "MIS"
              : "CNC"
            : payload.product == "NRML"
            ? "MIS"
            : "NRML",
        transType: Number(payload.netQty) > 0 ? "B" : "S",
        posType: "Day",
        orderSource: "WEB",
        token: payload.token,
      };
      await commonService
        .productConvertion(json)
        .then((response) => {
          if (
            response.data.status == "Ok" &&
            response.data.message == "Success"
          ) {
            errorHandling.toaster(
              "",
              "success",
              "Position convert successful",
              3000,
              "top-right",
              " ",
              " "
            );
            dispatch("getPositions");
          } else {
            errorHandling.toaster(
              "",
              "danger",
              response.data.message,
              3000,
              "top-right",
              " ",
              " "
            );
          }
        })
        .finally(() => {
          commit("handleConvertDialog", false);
        });
    },
    /****
     * @author Senthil
     * date 3-12-2021
     */
    async getPositions({ state, commit, rootGetters, dispatch }, payload) {
      commit("setResetCheckBox", []);

      state.postionLoader = true;

      let json = {
        ret: "NET",
      };
      await commonService
        .getUserPositions(json)
        .then(
          (response) => {
            if (response.status == 200 && response.data.status == "Ok") {
              dispatch("setPositions", response.data?.result);
            } else if (
              response.status == 200 &&
              response.data.stat == "Not_Ok" &&
              response.data.emsg == "Session Expired"
            ) {
              errorHandling.sessionExpire();
            }
            commit("setTabList");
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          state.postionLoader = false;
        });
    },

    async exitPosition({ state, commit, rootGetters }, payload) {
      state.exitLoader = true;
      var Array = [];

      for (let item of payload) {
        let json = {
          product: item.product,
          qty:
            item.exchange == "MCX"
              ? Math.abs(item.netQty * item.lotsize)
              : Math.abs(item.netQty),
          exchange: item.exchange,

          tradingSymbol: item.tradingsymbol,
          source: "WEB",
          price: item.netAvgPrice,
          transType: Number(item.netQty) > 0 ? "S" : "B",
          priceType: "L",
          orderType: item.orderType,
          ret: "DAY",
          triggerPrice: "",
          disclosedQty: "",
          mktProtection: "",
          stoploss: "",
          trailingPrice: "",
          target: "",
        };

        Array.push(json);
      }

      await commonService
        .squreOffAll(Array)
        .then(
          (response) => {
            if (response.status == 200) {
              commit("setPositionExitDialog", false);
              if (response && response.data) {
                response.data.forEach((el) => {
                  if (el.status == "Not ok") {
                    errorHandling.toaster(
                      "",
                      "danger",
                      "Square off order not placed"
                    );
                  } else if (
                    response.data["Emsg"] == "Session Expired" ||
                    response.data["emsg"] == "Session Expired"
                  ) {
                    errorHandling.sessionExpire();
                  }
                });
              }
            }
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          state.exitLoader = false;
          commit("handleExitDialog", false);
        });
    },

    setSocketValues({ state }, payload) {
      state.currentTableData.forEach((el) => {
        if (payload.tk == el.token) {
          el.ltp = !!payload.lp ? payload.lp : el.ltp;
          el.l = payload.l ? payload.l : el.l;
          el.h = payload.h ? payload.h : el.h;
          el.o = payload.o ? payload.o : el.o;
          el.volume = payload.v ? payload.v : el.volume;
          el.MtoM =
            mtom.methods.realisedProfitLoss(el, "mtm") +
            mtom.methods.unRealisedProfitLoss(el, "mtm");
          el.pnl =
            mtom.methods.realisedProfitLoss(el, "pnl") +
            mtom.methods.unRealisedProfitLoss(el, "pnl");
        }
      });
    },
    checkWebsocketStatus({ state, dispatch, rootGetters }, payload) {
      if (rootGetters["wsConnection/getConnectionStatue"]) {
        var tempData = {
          data: state.positionList,
          where: "position",
        };
        dispatch("wsConnection/websocketSubscription", tempData, {
          root: true,
        });
      }
    },
  },

  getters: {
    getPositionExitDialog: (state) => {
      return state.positionExitDialog;
    },
    getPositionConvertDialog: (state) => {
      return state.positionConvertDialog;
    },
    getPositionList: (state) => {
      return state.positionList;
    },
    getCurrentTableData: (state) => {
      return state.currentTableData;
    },
  },
};
export default position;
