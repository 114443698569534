import { mapState } from "vuex";
export default {
  methods: {
    listenGttTicks() {
      let tickerData = window.tickers;

      let currenToken =
        JSON.parse(localStorage.getItem("orderToken")) &&
        JSON.parse(localStorage.getItem("orderToken")) != "undefined"
          ? JSON.parse(localStorage.getItem("orderToken"))
          : null;

      if (
        !this.isSsobasket &&
        this.contractOrderData &&
        this.contractOrderData.length > 0
      ) {
        // let boken = this.$store.state.wsConnection.validToken;

        this.contractOrderData.forEach((el) => {
          let token = `${el.exchange}|${el.token}`;
          let actualToken = el.token;

          if (tickerData[token]) {
            var responseFeed = tickerData[token];

            //var el = this.contractOrderData[i];

            if (
              responseFeed.e &&
              responseFeed.e == el.exchange &&
              el.token == responseFeed.tk
            ) {
              currenToken && actualToken && currenToken == actualToken
                ? (this.$store.state.orderWindow.currentLtp = responseFeed.lp
                    ? responseFeed.lp
                    : responseFeed.c
                    ? responseFeed.c
                    : "")
                : "";

              el.ltp = responseFeed.lp ? responseFeed.lp : "0.00";

              el.exchange == "NSE"
                ? this.$store.commit(
                    "orderWindow/setNsePrice",
                    responseFeed.lp ? responseFeed.lp : "0.00"
                  )
                : "";

              el.exchange == "BSE"
                ? this.$store.commit(
                    "orderWindow/setBsePrice",
                    responseFeed?.lp ? responseFeed.lp : "0.00"
                  )
                : "";
              // responseFeed.lp && currenToken == actualToken
              //   ? (currentPrice = responseFeed.lp)
              //   : "";

              isNaN(this.$store.state.orderWindow.triggerPrice)
                ? this.$store.commit(
                    "orderWindow/setCurrentTrgPrice",
                    responseFeed.lp
                  )
                : "";

              responseFeed["52h"] != undefined && this.gttAction != "modify"
                ? this.$store.commit("gtt/set52WHPrice", responseFeed["52h"])
                : "";
            }
          }
        });
      }
    },
  },
  computed: {
    ...mapState("orderWindow", ["contractOrderData"]),
  },
};
