import store from "../../store";
import router from "../../router";
import { env } from "../../helpers/env";
import errorHandling from "@/handleError/errHandling";
const commonChart = {
  mounted() {
    window.addEventListener("message", checkEvent);
  },
  destroyed() {
    window.removeEventListener("message", checkEvent);
    store.commit("chart/setRefreshArray", "");
  },
};
export default commonChart;

function checkEvent(event) {
  const msgData = event.data.data;
  if (event.data.id === "ChartPopout" && router.currentRoute.path == "/chart") {
    var data = store.state.chart.tradeChartData;

    data.showPopout = false;
    data.isSymbolSearch = false;

    data.preloadProps = {
      interval: msgData.interval,
      indicators: msgData.indicators,
    };
    let url =
      window.location.protocol == "https:"
        ? env().depolyedUrl
        : env().depolyedUrl;

    let iframeUrl = url + "?data=" + JSON.stringify(data);
    let newWindowURL;
    if (window.location.hash.includes("#")) {
      newWindowURL = `${window.location.protocol}//${window.location.host}/#/popout`;
    } else {
      newWindowURL = `${window.location.protocol}//${window.location.host}/popout`;
    }

    localStorage.setItem("iframeUrl", JSON.stringify(iframeUrl));
    window.open(newWindowURL, "_blank");
  } else if (event.data.id === "startStream") {
    let streamSymbols = msgData.streamSyms ?? []; // List of symbol array need to subscribe
    
    if (streamSymbols.length > 0) {
      var chartMWData = []
      streamSymbols.forEach((el) => {
        el = el.toString().replace('#', '').trim()

        let scripToken=el.toString().split('|')?.[1]
        let scripExch=el.toString().split('|')?.[0]
        let subChart = {'exchange':scripExch, 'token':scripToken}
        chartMWData.push(subChart)
      });
      var tempData = {
        data:chartMWData,
        where: "tradingChart",
      };
      store.commit('chart/setStreamSymbol', chartMWData)
      store.dispatch("wsConnection/websocketSubscription", tempData, { root: true });
    }
      if (
        window.popout &&
        typeof window.popout?.callback === "function"
      ) {
        window.popout.callback();
      }
  
      if (
        window.chart &&
        typeof window.chart?.callback === "function"
      ) {
        window.chart.callback();
      }
  } else if (
    event.data.id === "currentSymbol" &&
    router.currentRoute.path == "/chart" && event.data.data.currentSymbol
  ) {
      let activeSymol = event.data.data.currentSymbol
      let lastData = store.state.chart.tradeChartData
      lastData.symbol = activeSymol.symbol
      lastData.token = activeSymol.token
      lastData.exchange = activeSymol.exchange
      store.commit('chart/setTardeData', lastData)
     } else if (event.data.id === "apiError") {
    let msg = {
      response: {
        status: event.data.data.error,
      },
    };
    errorHandling.errLog(msg)
  }else if (event.data.id === "indicatorsStoring") {
    const msgData = event.data.data
    store.commit("chart/setChartIndicators", msgData);
  }
}
