import errorHandling from "@/handleError/errHandling";
import { commonService } from "@/services/common.service";
const state = {
  exchangeData: [],
  futuresData: [],
  exchangeLoader: false,
  exchangeCondition: false,
  tabVal: "",
  pageVal: "",
};

const mutations = {
  resetState(state) {
    state.exchangeData = [];
    state.futuresData = [];
    state.exchangeLoader = false;
    state.exchangeCondition = false;
    state.tabVal = "";
    state.pageVal = "";
  },
  showExchange(state, payload) {
    state.exchangeCondition = payload;
  },
  setExchangeData(state, payload) {
    state.exchangeData = payload;
    this.dispatch("home/checkWebsocketStatus");
  },

  handleExchange(state, payload) {
    state.tabVal = payload.tab;
    state.pageVal = payload.page;
  },
  setLoader(state, payload) {
    state.exchangeLoader = payload;
  },
};

const actions = {
  checkWebsocketStatus({ state, dispatch, rootGetters }, payload) {
    if (rootGetters["wsConnection/getConnectionStatue"]) {
      var tempData = {
        data: state.exchangeData,
        where: "exchange",
      };

      dispatch("wsConnection/websocketSubscription", tempData, { root: true });
    }
  },
  async getEtfData({ commit }, payload) {
    commit("setLoader", true);
    await commonService
      .getEtfData()
      .then(
        (res) => {
          if (
            res.status == 200 &&
            res.data.status == "Ok" &&
            res.data.message == "Success"
          ) {
            const etfData = res.data.result.filter((el) => {
              return el.etfName == payload.toUpperCase();
            });
            let formatData = [];
            let tickers = window.tickers
            for (let item of etfData) {
              for (let scrip of item.scrips) {
                scrip.name = scrip.formattedInsName;
                scrip.open = scrip.close = scrip.high = scrip.low = '0.00';
                if(tickers && tickers[`${scrip.exchange}|${scrip.token}`]) {
                  let currData = tickers[`${scrip.exchange}|${scrip.token}`]
                  if(currData.o){
                    scrip.open = currData.o
                  }
                  if(currData.h){
                    scrip.high = currData.h
                  }
                  if(currData.l){
                    scrip.low = currData.l
                  }
                  if(currData.c){
                    scrip.close = currData.c
                  }
                }
                formatData.push(scrip);
              }
            }

            commit("setExchangeData", formatData);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },
  async getEquityData({ commit, rootState }, payload) {
    commit("setLoader", true);
    let dataGetter;
    if (payload == "Gainers") {
      dataGetter = "getTopGainers";
    } else if (payload == "Losers") {
      dataGetter = "getTopLosers";
    } else if (payload == "52-W High") {
      dataGetter = "get52WeekHigh";
    } else if (payload == "52-W Low") {
      dataGetter = "get52weeklow";
    }
    await commonService[dataGetter]()
      .then(
        (res) => {
          if (
            res.status == 200 &&
            res.data.status == "Ok" &&
            res.data.message == "Success"
          ) {
            let formatData = [];
            let tickers = window.tickers
            for (let scrip of res.data.result) {
              scrip.name = scrip.symbol;
              scrip.exchange = scrip.exch;
              scrip.open = scrip.close = scrip.high = scrip.low = '0.00';
                if(tickers && tickers[`${scrip.exchange}|${scrip.token}`]) {
                  let currData = tickers[`${scrip.exchange}|${scrip.token}`]
                  if(currData.o){
                    scrip.open = currData.o
                  }
                  if(currData.h){
                    scrip.high = currData.h
                  }
                  if(currData.l){
                    scrip.low = currData.l
                  }
                  if(currData.c){
                    scrip.close = currData.c
                  }
                }
              formatData.push(scrip);
            }
            commit("setExchangeData", formatData);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },
  async getFuturesData({ commit }, payload) {
    commit("setLoader", true);
    await commonService
      .getFutData()
      .then(
        (res) => {
          if (
            res.status == 200 &&
            res.data.status == "Ok" &&
            res.data.message == "Success"
          ) {
            const futData = res.data.result.filter((el) => {
              return el.futuresName == payload.toUpperCase();
            });
            let formatData = [];
            let tickers = window.tickers
            for (let item of futData) {
              for (let scrip of item.scrips) {
                scrip.name = scrip.scripName;
                scrip.open = scrip.close = scrip.high = scrip.low = '0.00';
                if(tickers && tickers[`${scrip.exchange}|${scrip.token}`]) {
                  let currData = tickers[`${scrip.exchange}|${scrip.token}`]
                  if(currData.o){
                    scrip.open = currData.o
                  }
                  if(currData.h){
                    scrip.high = currData.h
                  }
                  if(currData.l){
                    scrip.low = currData.l
                  }
                  if(currData.c){
                    scrip.close = currData.c
                  }
                }
                formatData.push(scrip);
              }
            }

            commit("setExchangeData", formatData);
          }
        },
        (err) => {
          errorHandling.errLog(err);
        }
      )

      .finally(() => {
        commit("setLoader", false);
      });
  },
};
const getters = {
  getExchangeData: (state) => state.exchangeData,
};
const home = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default home;
