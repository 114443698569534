import { commonService } from "../../services/common.service";
import errorHandling from "../../handleError/errHandling";
import router from "../../router/index";
import commom from "../Services/common";
function checkNullNan(value) {
  return value &&
    !!value &&
    value.toString() != "NA" &&
    value.toString() != "-" &&
    !isNaN(value)
    ? true
    : false;
}

function setQtyFocus() {
  let qtyInputBox = document.querySelector("#qty");
  qtyInputBox != null ? qtyInputBox.focus() : "";
}

const orderWindow = {
  namespaced: true,
  state: {
    // window btns state variables
    orderTypeList: ["Regular", "Cover", "Bracket", "AMO"],
    validityItems: ["DAY", "IOC"],
    bracketPriceItems: ["L", "MKT", "SL"],
    coverPriceItems: ["L", "MKT"],
    orderTab: 0,
    priceType: "L",
    prodType: "MIS",
    validityType: "DAY",
    brokerageData: "",
    showOrderWindow: false,
    removeCss: true,
    orderType: "buy",
    // orderSwitch
    toggleOrder: false,

    // qty & price Values
    minlot: "",
    ltp: "",

    triggerPrice: 0,
    tickPrice: "",
    disclosedQuantity: 0,
    quantity: "",
    price: 0,
    // disable inputs boolean
    isPrice: Boolean,
    isTrgPrice: Boolean,
    isDisclosedQty: Boolean,

    // price range data
    nseBseType: false,
    modifyOrder: false,

    // loading states
    loading: false,

    // by Bharath
    allPriceItems: ["L", "MKT", "SL", "SL-M"],
    postionPriceItems: ["L", "MKT", "SL"],
    showPriceItems: [],
    eqProductItems: ["MIS", "CNC"],
    allProductItems: ["MIS", "NRML"],
    showproductItems: [],

    // for Modify Order Data
    chartScripData: [],

    // nse switch state
    nseSwitchPrice: "0.00",
    bseSwitchPrice: "0.00",
    availableMargin: "0.00",
    marginLoader: false,

    contractOrderData: [],
    selectItem: 0,
    selectedData: [],
    currentPage: "",
    currentExchange: "",
    currentToken: "",
    formetScripName: "",
    currentLtp: 0,
    isBasket: false,
    targetPrice: 0,
    stopLossPrice: 0,
    trailStopLossPrice: 0,
    isSsobasket: false,
    isRepeat: false,
    isGtt: false,
    brokerageData: "",
    brokageDialog: false,
    brokerageLoader: false,
    isInstantOrder: false,
    freezeQty: 0,
    isBasketModify: false,
    isNudge: false,
    nudgeMessage: [],
    nudgeDialog: false,
    repeatOrderData: {},
    modifyOrderData: {},
  },
  mutations: {
    resetState(state) {
      state.orderTypeList = ["Regular", "Cover", "Bracket", "AMO"];
      state.validityItems = ["DAY", "IOC"];
      state.bracketPriceItems = ["L", "MKT", "SL"];
      state.coverPriceItems = ["L", "MKT"];
      state.orderTab = 0;
      state.priceType = "L";
      state.prodType = "MIS";
      state.validityType = "DAY";

      state.showOrderWindow = false;
      state.removeCss = true;
      state.orderType = "Buy";
      // orderSwitch
      state.toggleOrder = false;

      // qty & price Values
      state.minlot = "";
      state.ltp = "";

      state.triggerPrice = 0;
      state.tickPrice = "";
      state.disclosedQuantity = 0;
      state.quantity = "";
      state.price = 0;

      // disable inputs boolean
      state.isPrice = Boolean;
      state.isTrgPrice = Boolean;
      state.isDisclosedQty = Boolean;

      // price range data
      state.nseBseType = false;
      state.modifyOrder = false;

      // loading states
      state.loading = false;

      // by Bharath
      state.allPriceItems = ["L", "MKT", "SL", "SL-M"];
      state.postionPriceItems = ["L", "MKT", "SL"];
      state.showPriceItems = [];
      state.eqProductItems = ["MIS", "CNC"];
      state.allProductItems = ["MIS", "NRML"];
      state.showproductItems = [];

      // for Modify Order Data
      state.chartScripData = [];

      // nse switch state
      state.nseSwitchPrice = "0.00";
      state.bseSwitchPrice = "0.00";
      state.availableMargin = "0.00";
      state.marginLoader = false;

      state.contractOrderData = [];
      state.selectItem = 0;
      state.selectedData = [];
      state.currentPage = "";
      state.currentExchange = "";
      state.currentToken = "";
      state.formetScripName = "";
      state.currentLtp = 0;
      state.isBasket = false;
      state.targetPrice = 0;
      state.stopLossPrice = 0;
      state.trailStopLossPrice = 0;
      state.isSsobasket = false;
      state.isRepeat = false;
      state.brokerageData = "";
      state.brokageDialog = false;
      state.brokerageLoader = false;
      state.isInstantOrder = false;
      state.freezeQty = 0;

      state.isNudge = false;
      state.nudgeMessage = [];
      state.nudgeDialog = false;
    },
    setBrokerData(state, payload) {
      state.brokerageData = payload;
    },
    setRemoveCss(state, payload) {
      state.removeCss = payload;
    },
    setRepeatOrderData(state, payload) {
      state.repeatOrderData = payload;
    },
    setModifyOrderData(state, payload) {
      state.modifyOrderData = payload;
    },
    async setOrderWindow(state, payload) {
      state.showOrderWindow = payload;
      state.modifyOrder = false;
    },

    async setOrderWindowModify(state, payload) {
      state.showOrderWindow = payload;
      state.modifyOrder = true;
    },

    setOrderType(state, payload) {
      state.orderType = payload;
    },

    setChartScripData(state, payload) {
      (state.chartScripData = payload),
        localStorage.setItem("chartscrip", JSON.stringify(payload));
    },

    setCurrentQty(state, qty) {
      state.quantity = qty;
    },

    setCurrentPrice(state, price) {
      state.price = price;
    },

    setCurrentTrgPrice(state, trgPrice) {
      state.triggerPrice = trgPrice;
    },

    setPriceType(state, payload) {
      state.priceType = payload;
    },

    setProdType(state, payload) {
      state.prodType = payload;
    },

    setOrderTab(state, payload) {
      state.orderTab = payload;
    },

    setValidityType(state, payload) {
      state.validityType = payload;
    },

    // by Bharath
    setPriceItems(state, payload) {
      state.showPriceItems = payload;
    },

    setProductItems(state, payload) {
      state.showproductItems = payload;
    },

    changeOrderType(state, payload) {
      switch (payload) {
        case "regular": //Regular Order // We have taken (regular = amo) except for validity
        case "amo": {
          if (state.priceType == "L") {
            state.isPrice = true;

            state.isTrgPrice = false;
          } else if (state.priceType == "MKT") {
            state.isPrice = false;
            state.isTrgPrice = false;
          } else if (state.priceType == "SL") {
            state.isPrice = true;
            state.isTrgPrice = true;
          } else if (state.priceType == "SL-M") {
            state.isPrice = false;
            state.isTrgPrice = true;
          }
          break;
        }
        case "cover": {
          if (state.priceType == "L") {
            state.isPrice = true;
            state.isTrgPrice = true;
          } else if (state.priceType == "MKT") {
            state.isPrice = false;
            state.isTrgPrice = true;
          }
          // }
          break;
        }
        case "bracket": {
          // bracket Order validation
          if (state.priceType == "L") {
            state.isPrice = true;
            state.isTrgPrice = false;
          } else if (state.priceType == "SL") {
            state.isPrice = true;
            state.isTrgPrice = true;
          } else if (state.priceType == "MKT") {
            state.isPrice = false;
            state.isTrgPrice = false;
          } else if (state.priceType == "SL-M") {
            state.isPrice = false;
            state.isTrgPrice = true;
          }
          break;
        }
      }
      if (state.currentExchange == "NFO" || state.currentExchange == "BFO") {
        this.commit("orderWindow/setIsDisclosedQty", false);
      } else {
        this.commit("orderWindow/setIsDisclosedQty", true);
      }
    },

    setIsDisclosedQty(state, payload) {
      state.isDisclosedQty = payload;
    },

    setTickPrice(state, payload) {
      state.tickPrice = payload;
    },

    setValidityItems(state, payload) {
      state.validityItems = payload;
    },

    setDisclosedQty(state, payload) {
      state.disclosedQuantity = payload;
    },

    setOrderTypeList(state, payload) {
      state.orderTypeList = payload;
    },

    // set Available margin
    setAvailableMargin(state, payload) {
      state.availableMargin = payload;
    },

    // set Quantity with check Page
    valiDateQty(state, parameters) {
      if (state.currentPage == "holdings") {
        state.orderType.toLowerCase() == "buy"
          ? this.commit("orderWindow/setCurrentQty", state.minlot)
          : this.commit("orderWindow/setCurrentQty", state.selectedData.netQty);
      } else if (state.currentPage == "positions") {
        if (Number(state.selectedData.netQty) == 0) {
          this.commit("orderWindow/setCurrentQty", state.minlot);
        } else {
          if (
            Number(state.selectedData.netQty) > 0 &&
            state.orderType.toLowerCase() == "buy"
          ) {
            this.commit("orderWindow/setCurrentQty", state.minlot);
          } else if (
            Number(state.selectedData.netQty) > 0 &&
            state.orderType.toLowerCase() == "sell"
          ) {
            this.commit(
              "orderWindow/setCurrentQty",
              Math.abs(state.selectedData.netQty)
            );
          } else if (
            Number(state.selectedData.netQty) < 0 &&
            state.orderType.toLowerCase() == "buy"
          ) {
            this.commit(
              "orderWindow/setCurrentQty",
              Math.abs(state.selectedData.netQty)
            );
          } else if (
            Number(state.selectedData.netQty) < 0 &&
            state.orderType.toLowerCase() == "sell"
          ) {
            this.commit("orderWindow/setCurrentQty", state.minlot);
          }
        }
      } else if (state.currentPage == "orders") {
        //loglog
        this.commit(
          "orderWindow/setCurrentQty",
          state.modifyOrder || state.isRepeat
            ? state.selectedData.qty
            : state.minlot
        );
      } else if (state.currentPage == "gtt") {
        this.commit(
          "orderWindow/setCurrentQty",
          state.selectedData.qty ? state.selectedData.qty : state.minlot
        );
      } else {
        this.commit("orderWindow/setCurrentQty", state.minlot);
      }

      let insType = state.contractOrderData[state.selectItem].insType;
    },
    setMarginLoader(state, payload) {
      state.marginLoader = payload;
    },
    setMinLot(state, payload) {
      state.minlot = payload;
    },
    setNsePrice(state, payload) {
      state.nseSwitchPrice = payload;
    },
    setBsePrice(state, payload) {
      state.bseSwitchPrice = payload;
    },
    setTargetPrice(state, payload) {
      state.targetPrice = payload;
    },
    setStopLossPrice(state, payload) {
      state.stopLossPrice = payload;
    },
    setTrailStopLossPrice(state, payload) {
      state.trailStopLossPrice = payload;
    },
    setToken(state, payload) {
      state.currentToken = payload;
      localStorage.setItem("orderToken", JSON.stringify(payload));
    },
    resetOrderkey(state, payload) {
      state.currentToken = null;
      state.selectedData = [];
      state.contractOrderData = [];
    },
    setBrokerageData(state, payload) {
      state.brokerageData = payload;
    },
    setBrokageDialog(state, payload) {
      state.brokageDialog = payload;
    },
    setIsInstantOrder(state, payload) {
      state.isInstantOrder = payload;
    },
    setBracketPriceItems(state, payload) {
      state.bracketPriceItems = payload;
    },
    setIsNudge(state, payload) {
      state.isNudge = payload;
    },
    setNudgeMessage(state, payload) {
      state.nudgeMessage = payload;
    },
    setNudgeDialog(state, payload) {
      state.nudgeDialog = payload;
    },
  },
  actions: {
    // place order service
    async placeOrder(
      { state, commit, rootGetters, rootState, dispatch },
      payload
    ) {
      let exchange = state.contractOrderData[state.selectItem].exchange;
      let qty;

      if (exchange == "MCX") {
        qty = (
          Number(state.quantity) *
          Number(state.contractOrderData[state.selectItem].actual_lotSize)
        ).toString();
      } else {
        qty = state.quantity.toString();
      }
      var marketType = state.orderTypeList[state.orderTab];

      var stopLoss =
        marketType == "Cover"
          ? state.triggerPrice
            ? state.triggerPrice.toString()
            : ""
          : state.stopLossPrice
          ? state.stopLossPrice.toString()
          : "";
      var target =
        marketType == "Bracket"
          ? state.targetPrice
            ? state.targetPrice.toString()
            : ""
          : "";

      var trail = payload.trailStopLoss ? payload.trailStopLoss : "";

      var prc = state.isPrice ? (state.price ? String(state.price) : "0") : "0";
      let placeOrder = [
        {
          exchange: state.contractOrderData[state.selectItem].exchange,
          tradingSymbol:
            state.contractOrderData[state.selectItem].tradingSymbol,
          qty: qty,
          price: state.priceType == "MKT" ? "0" : prc,
          product: state.prodType,
          priceType: state.priceType,
          orderType: marketType,
          transType: state.orderType === "sell" ? "S" : "B",
          ret: state.validityType ? state.validityType.toUpperCase() : "DAY",
          triggerPrice:
            marketType == "Cover"
              ? ""
              : state.isTrgPrice
              ? state.triggerPrice.toString()
              : "",
          disclosedQty: state.disclosedQuantity ? state.disclosedQuantity : "",
          mktProtection: "",
          target: target,
          stopLoss: stopLoss,
          trailingStopLoss: trail,
          token: state.contractOrderData[state.selectItem].token,
          source: rootState.login.source ? rootState.login.source : "",
        },
      ];
      rootState["stickyOrderCondition"] ? "" : commit("setOrderWindow", false);

      if (state.isBasket) {
        placeOrder[0]["token"] =
          state.contractOrderData[state.selectItem].token;

        state.isBasketModify
          ? dispatch("basket/updateScripInBasket", payload, { root: true })
          : dispatch("basket/addScripInBasket", placeOrder, { root: true });
      } else {
        state.loading = true;
        await commonService
          .placeOrder(placeOrder)
          .then(
            (response) => {
              if (
                response.status == 200 &&
                response.data &&
                Array.isArray(response.data)
              ) {
                response.data.forEach((el) => {
                  if (el?.status == "Not_Ok") {
                    let error = el["message"] ? el["message"] : "Failed";
                    el["message"] == "Session Expired"
                      ? errorHandling.sessionExpire()
                      : errorHandling.toaster(
                          error,
                          "danger",
                          "",
                          3000,
                          "top-right",
                          ""
                        );
                  }
                });
              }

              rootState["stickyOrderCondition"]
                ? ""
                : commit("setOrderWindow", false);
            },
            (err) => {
              errorHandling.errLog(err);
            }
          )
          .finally(() => {
            state.loading = false;
          });
      }
      rootState["stickyOrderCondition"] ? "" : commit("resetOrderkey");
    },

    // placeBasketOrder service
    async placeBasketOrder({ state, commit, rootGetters }, payload) {
      state.loading = true;
      let placeOrder = [];
      payload.data.forEach((element) => {
        placeOrder.push({
          active_status: null,
          complexity: element.complexity,
          created_by: null,
          created_on: null,
          disclosedQty: element.disclosedQuantity
            ? element.disclosedQuantity
            : 0,
          emsg: null,
          exchange: element.exchange,
          id: null,
          instrument: element.instrument,
          link: null,
          master_id: 0,
          product: element.product
            ? element.product.toLowerCase()
            : element.product,
          priceType: element.orderType,
          price: element.price ? element.price : 0,
          qty: element.quantity,
          reponse: null,
          ret: element.validity ? element.validity.toUpperCase() : "DAY",
          salt: null,
          stat: null,
          stopLoss: element.stoploss ? parseInt(element.stopLoss) : 0,
          token: element.token,
          target: element.stoploss ? parseInt(element.stopLoss) : 0,
          tradingSymbol: element.tradingSymbol,
          trailingStopLoss: element.trailingStoploss
            ? parseInt(element.trailingStoploss)
            : 0,
          transtype: element.transactionType
            ? element.transactionType.toLowerCase()
            : element.transactionType,
          triggerPrice: element.triggerPrice ? element.triggerPrice : 0,
          updated_by: null,
          updated_on: null,
        });
      });
      state.loading = false;
      await service
        .executeOrder(placeOrder)
        .then(
          (response) => {
            if (
              response.status == 200 &&
              response.data &&
              Array.isArray(response.data)
            ) {
              response.data.forEach((el) => {
                if (el?.stat == "Not_Ok") {
                  let error = el["emsg"] ? el["emsg"] : "Failed";
                  el["emsg"] == "Session Expired"
                    ? errorHandling.sessionExpire()
                    : errorHandling.toaster(
                        error,
                        "danger",
                        "",
                        3000,
                        "top-right",
                        ""
                      );
                }
              });
            }

            commit("login/setSsoQuery", "", { root: true });
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          state.loading = false;
        });
    },

    // Modify order module
    async modifyOrder({ state, commit, rootGetters, rootState }, payload) {
      var marketType = state.orderTypeList[state.orderTab];

      let priceType =
        state.selectedData["product"] == "CO"
          ? state.selectedData["priceType"]
          : payload.priceType;
      let placeOrder = {
        disclosedQty:
          payload.complexity == "bracket"
            ? payload.disclosedQty.toString()
            : payload.disclosedQty,
        exchange: state.selectedData["exchange"],
        segment: state.selectedData["segment"],

        mktProtection: state.selectedData["mktProtection"]
          ? state.selectedData.mktProtection
          : "",
        orderNo: state.selectedData["orderNo"],
        product: state.selectedData["product"],
        priceType: priceType,
        price: priceType == "MKT" ? "0" : payload.price.toString(),
        qty: payload.qty,
        token: state.selectedData["token"],

        tradingSymbol: state.selectedData["tradingSymbol"],
        transtype: state.orderType,
        triggerPrice:
          marketType == "Cover"
            ? ""
            : state.isTrgPrice
            ? payload.triggerPrice.toString()
            : "",

        ret: payload.validity ? payload.validity.toUpperCase() : "DAY",
      };
      state.loading = true;
      await commonService
        .modifyOrder(placeOrder)
        .then(
          (response) => {
            if (response.status == 200 && response.data.status == "Ok") {
            } else {
              errorHandling.toaster(
                "Order not modified",
                "danger",
                "",
                3000,
                "top-right",
                " ",
                " "
              );
            }
            rootState["stickyOrderCondition"]
              ? ""
              : commit("setOrderWindow", false);
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          state.loading = false;
        });
      rootGetters["getStrikyOrder"] ? "" : commit("resetOrderkey");
    },

    setCurrentLtp(state, payload) {
      state.currentLtp =
        payload && isNaN(payload)
          ? "0.00"
          : state.currentExchange == "CDS" || state.currentExchange == "BCD"
          ? parseFloat(payload).toFixed(4)
          : parseFloat(payload).toFixed(2);
    },

    async getScripQuoteDetails(
      { state, commit, dispatch, rootGetters },
      payload
    ) {
      if (payload.page == "chart") {
        var exch = payload.exch;
        var token = payload.token;
      }
      let json = {
        exch: exch,
        symbol: token,
      };
      await service
        .getScripQuoteDetails(json)
        .then(
          (response) => {
            if (response.data.emsg == "Session Expired") {
              errorHandling.sessionExpire();
            } else if (response.status == 200) {
              if (payload.page == "chart") {
                response.data.LTP =
                  payload.from == "mkWatch"
                    ? rootGetters["getChartData"]["data"]["ltp"]
                    : payload.from == "position"
                    ? rootGetters["getChartData"]["data"]["LTP"]
                    : payload.from == "holdings"
                    ? rootGetters["getChartData"]["data"]["chartLtp"]
                    : payload.from == "holdings" &&
                      rootGetters["getChartData"]["data"]["ltp"]
                    ? rootGetters["getChartData"]["data"]["ltp"]
                    : response.data.LTP;
                response.data.curVolume =
                  rootGetters["getChartData"]["data"]["volume"];
                commit("setChartScripData", response.data);
              }
            }
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {});
    },
    // check margin availble
    async getMarginAvailable(
      { state, commit, dispatch, rootGetters },
      payload
    ) {
      commit("setMarginLoader", true);
      let exchange = state.contractOrderData && state.contractOrderData.length > 0 && state.contractOrderData[state.selectItem].exchange
        ? state.contractOrderData[state.selectItem].exchange
        : exchange;
      let qty;
      if (exchange == "MCX") {
        qty = (
          Number(state.quantity) *
          Number(state.contractOrderData[state.selectItem].actual_lotSize)
        ).toString();
      } else {
        qty = state?.quantity?.toString();
      }
      var marketType = state.orderTypeList[state.orderTab];
      var stopLoss =
        marketType == "Cover"
          ? state.triggerPrice
            ? state.triggerPrice.toString()
            : "0"
          : marketType == "Bracket"
          ? state.stopLossPrice
            ? state.stopLossPrice.toString()
            : "0"
          : "";
      var target =
        marketType == "Bracket"
          ? state.targetPrice
            ? state.targetPrice.toString()
            : "0"
          : "";

      let json1 = {
        exchange: state.currentExchange,
        // token: state.contractOrderData[state.selectItem].token,
        tradingSymbol: state.contractOrderData[state.selectItem].tradingSymbol,
        qty: qty,
        price: state.isPrice ? String(state?.price) : await state.currentLtp,
        triggerPrice:
          marketType == "Cover"
            ? ""
            : state.isTrgPrice
            ? state.triggerPrice.toString()
            : "0",
        stopLoss: stopLoss,
        target: target,
        transType: state.orderType === "sell" ? "S" : "B",
        product: state.prodType,
        priceType: state.priceType,
        orderType: marketType,
      };
      await commonService
        .getMargin(json1)
        .then(
          (response) => {
            if (
              response.status == 200 &&
              response.data.status == "Ok" &&
              response.data.result &&
              response.data.result.length > 0
            ) {
              commit(
                "setAvailableMargin",
                response.data.result[0]["requiredMargin"]
              );
            } else if (
              response.data.stat == "Not ok" &&
              response.data.Emsg == "Session Expired"
            ) {
              errorHandling.sessionExpire();
            } else {
              commit("setAvailableMargin", "NA");
            }
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {
          commit("setMarginLoader", false);
        });
    },
    async calcBrokerage({ state, commit, dispatch, rootGetters }, payload) {
      var data = rootGetters["getBuysellpopup"];
      try {
        let insType = state.contractOrderData[state.selectedItem].insType;
        let qty;
        if (insType == "FUTCOM" || insType == "OPTFUT") {
          qty = (
            Number(state.quantiy) *
            Number(state.contractOrderData[state.selectedItem].actual_lot_size)
          ).toString();
        } else {
          qty = state.quantiy.toString();
        }
        let json = {
          user: rootGetters["login/getUserId"],
          qty: qty,
          price:
            data.marketType == "Cover" && state.priceTypeVal == "Market"
              ? state.currentLtp
              : state.price && isNaN(state.price) == false
              ? state.price.toString()
              : "0",
          token: state.contractOrderData[state.selectedItem].token,
          lotSize: state.contractOrderData[state.selectedItem].actual_lot_size,
          plan: "T-pro",
          segment: state.currentExch,
          type: data.tradeType == "MIS" ? "INTRADAY" : "DELIVERY",
          instrumentType:
            state.currentExch == "NSE" || state.currentExch == "BSE"
              ? "EQ"
              : state.contractOrderData[state.selectedItem].insType,
          transactionType: state.buySellToggleName === "SELL" ? "sell" : "buy",
        };
        var qtyLot = validation.methods.floatSafeRemainder(qty, state.minlot);
        if (qtyLot != 0 && state.minlot > 0) {
        } else {
          let response = await commonService.getBrokerage(json);
          if (response.status == 200 && response.data?.result) {
            commit("setBrokerData", response.data?.result);
          } else {
            commit("setBrokerData", []);
          }
        }
      } catch (error) {
        commit("setIsLoader", false, { root: true });
        // errHandle.methods.errorHandle(error);
      }
    },
    // contract Info
    async getContract({ state, commit, dispatch, rootGetters }, payload) {
      commit("setNudgeDialog", false);
      commit("setBracketPriceItems", ["L", "MKT", "SL"]);
      commit("setIsInstantOrder", false);
      dispatch("unsubScribe");
      if (payload.isRepeat) {
        commit("setRepeatOrderData", payload.data);
      }

      state.isBasketModify = payload.isBasketModify;

      window.goodTillTriggered = {
        callback: window.goodtillWindow,
      };

      window.ow = {
        callback: window.orderWindow,
      };

      let json = {
        token: payload.data.token,
        exch: payload.data.exchange ? payload.data.exchange : payload.data.exch,
      };
      await commonService
        .getContractInfo(json)
        .then(
          async (resp) => {
            if (resp.status == 200 && resp.data.result?.length > 0) {
              payload.contract = resp.data.result[0].scrips;
              resp.data.result[0]?.freezeQty
                ? (state.freezeQty = resp.data.result[0]?.freezeQty)
                : (state.freezeQty = 0);

              await dispatch("setContractOrderData", payload);
              await dispatch("setDefaultValues", {
                exch: json.exch,
                action: "intial",
              });
            }
          },
          (err) => {
            errorHandling.errLog(err);
          }
        )
        .finally(() => {});
    },
    async setDefaultValues({ state, commit, dispatch, rootState }, payload) {
      let tickers = window.tickers;
      state.contractOrderData.forEach((el) => {
        let token = `${el.exchange}|${el.token}`;

        el.ltp = el.bp1 = el.bq1 = el.price = "0.00";
        if (tickers && tickers[token]) {
          el.ltp = tickers[token]?.lp
            ? tickers[token].lp
            : tickers[token].c
            ? tickers[token].c
            : "0.00";
        }
        commit("setAvailableMargin", "0.00");
        if (state.nseBseType) {
          if (el.exchange == "NSE") {
            commit("setNsePrice", el.ltp);

            dispatch("setCurrentLtp", el.ltp);
          } else {
            commit("setBsePrice", el.ltp);

            dispatch("setCurrentLtp", el.ltp);
          }
        } else {
          dispatch("setCurrentLtp", el.ltp);
        }
        rootState.gtt.gttAction != "modify"
          ? el.ltp != "0.00"
            ? commit("gtt/set52WHPrice", el.ltp, {
                root: true,
              })
            : ""
          : "";
      });
    },
    async setContractOrderData(
      { state, commit, dispatch, root, rootState },
      payload
    ) {
      state.selectedData = payload.data;
      state.contractOrderData = payload.contract;
      state.isBasket = payload.isBasket;
      state.isGtt = payload.isGtt;
      if (payload.isInstantOrder) {
        rootState.gtt.gttWindow = false;
        commit("setIsInstantOrder", true);
        state.showOrderWindow = false;
      } else if (payload.isGtt) {
        state.showOrderWindow = false;
        rootState.gtt.gttWindow = true;
      } else {
        rootState.gtt.gttWindow = false;
        state.showOrderWindow = payload.page == "convert" ? false : true;
      }

      state.modifyOrder = payload.isModify;
      state.orderType = payload.orderType;
      state.currentPage = payload.page;
      //  state.isSsobasket = payload.isBasketModify;
      state.isRepeat = payload.isRepeat;
      state.currentExchange = payload.isBasketModify
        ? payload.data.exchange
        : payload.data.exchange
        ? payload.data.exchange
        : payload.data.exch;
      commit("setToken", null);

      (state.currentExchange == "NSE" &&
        state.contractOrderData?.length == 2) ||
      (state.currentExchange == "BSE" && state.contractOrderData?.length == 2)
        ? (state.nseBseType = true)
        : (state.nseBseType = false);

      await dispatch("activeScrip");
    },

    async activeScrip({ state, commit, dispatch, rootGetters }, payload) {
      payload ? (state.currentExchange = payload) : "";

      state.selectItem = state.contractOrderData.findIndex(
        (std) => std.exchange === state.currentExchange
      );

      commit("setToken", state.contractOrderData[state.selectItem].token);
      state.formetScripName = state.contractOrderData[state.selectItem]
        .formattedInsName
        ? state.contractOrderData[state.selectItem].formattedInsName
        : state.contractOrderData[state.selectItem].tradingSymbol;
      state.contractOrderData[state.selectItem].actual_lotSize = !state
        .contractOrderData[state.selectItem].lotSize
        ? 0
        : state.contractOrderData[state.selectItem].lotSize;
      let minlot = !state.contractOrderData[state.selectItem].lotSize
        ? 0
        : (state.contractOrderData[state.selectItem].insType == "FUTCOM" ||
            state.contractOrderData[state.selectItem].insType == "OPTFUT" ||
            state.contractOrderData[state.selectItem].insType == "FUTIDX") &&
          state.currentExchange == "MCX"
        ? state.contractOrderData[state.selectItem].lotSize /
          state.contractOrderData[state.selectItem].lotSize
        : state.contractOrderData[state.selectItem].lotSize;
      commit("setMinLot", minlot);
      let checkLtp = "";

      if (state.isSsobasket) {
        checkLtp =
          state.currentExchange == "CDS" || state.currentExchange == "BCD"
            ? parseFloat(state.selectedData.ltp).toFixed(4)
            : parseFloat(state.selectedData.ltp).toFixed(2);
        await dispatch("setCurrentLtp", checkLtp);
      }

      if (state.nseBseType) {
        var nseIndex = state.contractOrderData.findIndex(
          (std) => std.exchange === "NSE"
        );
        var bseIndex = state.contractOrderData.findIndex(
          (std) => std.exchange === "BSE"
        );

        var tempData = {
          sub: null,
          statge: "initial",
        };

        tempData.sub = `${state.contractOrderData[nseIndex].exchange}|${state.contractOrderData[nseIndex].token}`;
        await dispatch("wsConnection/ortherSubscription", tempData, {
          root: true,
        });

        tempData.sub = `${state.contractOrderData[bseIndex].exchange}|${state.contractOrderData[bseIndex].token}`;
        await dispatch("wsConnection/ortherSubscription", tempData, {
          root: true,
        });
        let nseLtp;
        if (!state.isSsobasket) {
          if (
            state.contractOrderData[nseIndex] &&
            Number(state.contractOrderData[nseIndex].ltp) > 0
          ) {
            nseLtp = state.contractOrderData[nseIndex].ltp;
          } else {
            nseLtp = "";
          }
        }

        let bseLtp;
        if (!state.isSsobasket) {
          if (
            state?.contractOrderData[bseIndex].ltp &&
            Number(state.contractOrderData[bseIndex].ltp) > 0
          ) {
            bseLtp = state?.contractOrderData[bseIndex].ltp;
          } else {
            bseLtp = "";
          }
        }
        state.nseSwitchPrice = isNaN(nseLtp)
          ? "0.00"
          : parseFloat(nseLtp).toFixed(2);
        state.bseSwitchPrice = isNaN(bseLtp)
          ? "0.00"
          : parseFloat(bseLtp).toFixed(2);
      } else {
        if (!payload) {
          var tempData = {
            sub: state.currentExchange + "|" + state.currentToken,
            statge: "initial",
          };
          state.selectedData?.token !=
          state.contractOrderData[state.selectItem].token
            ? (state.contractOrderData[state.selectItem].pdc = state.currentLtp)
            : "";
          await dispatch("wsConnection/ortherSubscription", tempData, {
            root: true,
          });
        }
      }

      if (state.isSsobasket) {
        let singleLtp;
      }

      commit(
        "setTickPrice",
        state.contractOrderData[state.selectItem].tickSize
      );

      setTimeout(() => {
        let prc = "0.00";
        prc =
          state.currentPage == "chart"
            ? state.selectedData.ltp
            : state.currentLtp;

        if (state.isPrice && state.isRepeat) {
          prc = state.repeatOrderData?.price;
        }
        if (state.isPrice && state.modifyOrder) {
          prc = state.selectedData.price;
        }
        if (state.isGtt) {
          prc = state.selectedData.price
            ? state.selectedData.price
            : state.currentLtp;
        }

        commit("setCurrentPrice", prc);

        commit(
          "setCurrentTrgPrice",
          state.currentPage == "chart"
            ? state.selectedData.ltp
            : state.selectedData.triggerPrice
            ? state.selectedData.triggerPrice
            : state.currentLtp
        );
        if (state.isGtt) {
          commit(
            "setCurrentTrgPrice",
            state.currentPage == "chart"
              ? state.selectedData.ltp
              : state.selectedData.gttValue
              ? state.selectedData.gttValue
              : state.currentLtp
          );
        }
      }, 300);

      commit("setTargetPrice", 0);
      commit("setStopLossPrice", 0);
      commit("setTrailStopLossPrice", 0);

      if (state.modifyOrder) {
        commit(
          "setCurrentPrice",
          Number(state.selectedData.price) != 0
            ? state.selectedData.price
            : state.currentLtp
            ? state.currentLtp
            : checkNullNan(state.selectedData.ltp)
            ? state.selectedData.ltp
            : ""
        );

        commit(
          "setCurrentTrgPrice",
          checkNullNan(state.selectedData.triggerPrice) &&
            parseFloat(state.selectedData.triggerPrice) != 0
            ? state.selectedData.triggerPrice
            : state.currentLtp
            ? state.currentLtp
            : ""
        );
        if (state.isGtt) {
          commit(
            "setCurrentTrgPrice",
            checkNullNan(state.selectedData.gttValue) &&
              parseFloat(state.selectedData.gttValue) != 0
              ? state.selectedData.gttValue
              : state.currentLtp
              ? state.currentLtp
              : ""
          );
        }
        commit(
          "setDisclosedQty",
          checkNullNan(state.selectedData.Dscqty)
            ? state.selectedData.Dscqty
            : 0
        );

        commit("setTargetPrice", 0);
        commit("setStopLossPrice", 0);
        commit("setTrailStopLossPrice", 0);
      }

      state.isSsobasket
        ? commit("setCurrentQty", state.selectedData.quantity)
        : commit("valiDateQty", "");

      if (state.isRepeat) {
        if (!payload) {
        } else {
          setTimeout(() => {
            dispatch(
              "setCurrentLtp",
              Number(state.selectedData.ltp) == 0
                ? state.currentLtp
                : checkNullNan(state.selectedData.ltp)
                ? state.selectedData.ltp
                : "0.00"
            );
          }, 100);
        }

        commit(
          "setCurrentTrgPrice",
          state.selectedData.orderType == "Cover"
            ? parseFloat(state.selectedData.stopLoss) != 0
              ? state.selectedData.stopLoss
              : state.currentLtp
            : checkNullNan(state.selectedData.triggerPrice)
            ? parseFloat(state.selectedData.triggerPrice) != 0
              ? state.selectedData.triggerPrice
              : state.currentLtp
            : ""
        );
        commit(
          "setTargetPrice",
          state.selectedData.orderType == "Bracket"
            ? parseFloat(state.selectedData.target) != 0
              ? state.selectedData.target
              : 0
            : 0
        );
        commit(
          "setStopLossPrice",
          state.selectedData.orderType == "Bracket"
            ? parseFloat(state.selectedData.stopLoss) != 0
              ? state.selectedData.stopLoss
              : 0
            : 0
        );
        commit("setDisclosedQty", 0);
      }
      setQtyFocus();
      if (state.isSsobasket) {
        commit("setOrderTypeList", state.orderTypeList);
        var tab =
          state.selectedData.orderType == "Regular"
            ? "Regular"
            : state.selectedData.orderType == "Cover"
            ? "Cover"
            : state.selectedData.orderType == "Bracket"
            ? "Bracket"
            : "AMO";
        commit("setOrderTab", state.orderTypeList.indexOf(tab));
        dispatch("common/setProdTypeCommon", "sso", { root: true });
      } else {
        if (!payload) {
          dispatch(
            "common/setOrderTypeCommon",
            state.contractOrderData[state.selectItem],
            { root: true }
          );
        }
      }
      commit("setBrokerageData", "");

      if (
        state.contractOrderData[state.selectItem].prompt &&
        state.contractOrderData[state.selectItem].prompt.length > 0
      ) {
        commit(
          "setNudgeMessage",
          state.contractOrderData[state.selectItem].prompt
        );
        commit("setIsNudge", true);
      } else {
        commit("setNudgeMessage", []);
        commit("setIsNudge", false);
      }
    },
    unsubScribe({ state, commit, dispatch, rootGetters }, payload) {
      if (rootGetters["wsConnection/getOtherSubscription"]) {
        let temp = {
          sub: rootGetters["wsConnection/getOtherSubscription"],
          statge: "esc",
        };
        dispatch("wsConnection/ortherSubscription", temp, { root: true });
      }
      delete window.ow;
      delete window.goodTillTriggered;
    },
    async getBrokerageData({ state, commit, dispatch, rootGetters }, payload) {
      if (
        state.contractOrderData &&
        state.contractOrderData?.length > 0 &&
        state.contractOrderData[state.selectItem]
      ) {
        let json = {};
      }
    },
  },

  getters: {
    getPriceType(state) {
      return state.priceType;
    },
    getProdType(state) {
      return state.prodType;
    },
    getOrderTab(state) {
      return state.orderTab;
    },
    getValidityType(state) {
      return state.validityType;
    },
    getTriggerPrice(state) {
      return state.triggerPrice;
    },
    getCurrentLtp: (state) => {
      return state.currentLtp;
    },
    getCurrentToken: (state) => {
      return state.currentToken;
    },
    getAvailableMargin: (state) => {
      return state.availableMargin;
    },
    getOrderTabValue: (state) => {
      return state.orderTypeList[state.orderTab] == "Regular"
        ? "regular"
        : state.orderTypeList[state.orderTab] == "Cover"
        ? "cover"
        : state.orderTypeList[state.orderTab] == "Bracket"
        ? "bracket"
        : state.orderTypeList[state.orderTab] == "AMO"
        ? "amo"
        : "regular";
    },
  },
};

export default orderWindow;
