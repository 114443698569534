<template>
  <v-app v-resize="onMobileSize">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),

  methods: {
    onMobileSize() {
      window.innerWidth <= 1023
        ? this.$store.dispatch("setMobileView", true)
        : this.$store.dispatch("setMobileView", false);
      window.innerWidth > 1599
        ? this.$store.dispatch("setLapView", true)
        : this.$store.dispatch("setLapView", false);
      window.innerWidth <= 1023
        ? this.$store.dispatch("header/setMenuList", true)
        : this.$store.dispatch("header/setMenuList", false);
      this.$store.dispatch("setWindowInnerHeight", window.innerHeight);
      this.$store.dispatch("setWindowInnerWidth", window.innerWidth);
      window.innerWidth >= 1024 &&
      this.$router.currentRoute.path == "/marketwatch"
        ? this.$router.push("/home").catch(() => {})
        : "";
      this.$store.dispatch("header/getMenuList");
      if (this.$store.state.activePageTab == "Basket") {
        window.innerWidth <= 1200
          ? this.$store.dispatch("setBasketResponsive", true)
          : this.$store.dispatch("setBasketResponsive", false);
      }
    }
  },
  updated() {
    this.onMobileSize();
  },
};
</script>
