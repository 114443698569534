import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./style/index.css";
import "./style/common.css";
import tabMenuVue from "./components/tabMenu.vue";
import noData from "./components/noData.vue";
import customIconVue from "./components/customIcon.vue";

// customized toasts
import { default as notification } from "./components/Notification/index";
Vue.prototype.$notification = notification;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
Vue.component("tab-menu", tabMenuVue);
Vue.component("noData", noData);
Vue.component("customIcon", customIconVue);
