<template>
  <v-bottom-sheet v-model="bottomSheet">
    <v-card min-height="50vh">
      <ul v-if="page" class="bottomSheetUl">
        <!-- market Watch  -->
        <li
          class="bottomSheetList"
          v-if="
            page == 'MkWatch' &&
            currentData[0].item.segment &&
            currentData[0].item.segment.includes('_idx')
          "
          @click="sendToParent('buy')"
        >
          <div class="d-flex align-center">
            <span>&#43;</span>
            <span class="ml-3">Buy</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'MkWatch' &&
            currentData[0].item.segment &&
            currentData[0].item.segment.includes('_idx')
          "
          @click="sendToParent('sell')"
        >
          <div class="d-flex align-center">
            <span>&#8722;</span>
            <span class="ml-3">Sell</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'MkWatch' &&
            currentData[0].item.segment &&
            currentData[0].item.segment.includes('_idx') && currentData[0].item.exchange != 'CDS' && currentData[0].item.exchange != 'BFO' && currentData[0].item.exchange != 'BCD'
          "
          @click="sendToParent('gtt')"
        >
          <div class="d-flex align-center">
            <img
              src="../assets/images/gtt.png"
              alt=""
              width="20px"
              height="20px"
            />
            <span class="ml-3">Create GTT</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'MkWatch'"
          @click="sendToParent('chart')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'chart'"
              :width="'24'"
              :height="'24'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Chart</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'MkWatch'"
          @click="sendToParent('depth')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'depth'"
              :width="'20'"
              :height="'20'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Market Depth</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'MkWatch' || page == 'BasketListing'"
          @click="sendToParent('del')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'delete'"
              :width="'20'"
              :height="'20'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Delete</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'MkWatch' &&
            currentData[0].item.segment &&
            currentData[0].item.segment.includes('_idx')
          "
          @click="sendToParent('Alert')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :height="'20'"
              :width="'20'"
              :name="'notiAlerts'"
            ></customIcon>

            <span class="ml-3">Alert</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'MkWatch' && currentData[0].item.exchange == 'NFO'"
          @click="sendToParent('oc')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :height="'20'"
              :width="'20'"
              :name="'oc'"
            ></customIcon>
            <span class="ml-3">Option Chain</span>
          </div>
        </li>

        <li
          class="bottomSheetList"
          v-if="
            page == 'MkWatch' &&
            currentData[0].item.segment &&
            currentData[0].item.segment.includes('_idx')
          "
          @click="sendToParent('info')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="d-flex align-center"
              :name="'info'"
              :width="'20'"
              :height="'20'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Info</span>
          </div>
        </li>

        <li
          class="bottomSheetList"
          v-if="page == 'MkWatch' && this.currentData[0]['item']['ex'] == 'NFO'"
          @click="sendToParent('opt')"
        >
          <div class="d-flex align-center">
            <img
              src="../assets/dashboardImages/option_chain_new.svg"
              alt=""
              width="20px"
              height="20px"
            />
            <span class="ml-3">Option Chain</span>
          </div>
        </li>

        <li
          class="bottomSheetList"
          v-if="
            page == 'MkWatch' &&
            (this.currentData[0]['item']['ex'] == 'NSE' ||
              this.currentData[0]['item']['ex'] == 'BSE')
          "
          @click="sendToParent('funda')"
        >
          <div class="d-flex align-center">
            <img
              src="../assets/dashboardImages/smallcase_logo.svg"
              alt=""
              width="20px"
              height="20px"
            />
            <span class="ml-3">Fundamentals</span>
          </div>
        </li>
        <!-- Orders  -->

        <li
          class="bottomSheetList"
          v-if="page == 'Order' && this.currentData[0]['tab'] == 'Pending'"
          @click="sendToParent('modify')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-pencil-outline</v-icon></span>
            <span class="ml-3">Modify</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'BasketListing' || page == 'Alert' || page == 'Gtt'"
          @click="sendToParent('modify')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-pencil-outline</v-icon></span>
            <span class="ml-3">Modify</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'Order' &&
            this.currentData[0]['tab'] !== 'Pending' &&
            this.currentData[0]['tab'] !== 'Baskets'
          "
          @click="sendToParent('Repeat')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-rotate-3d-variant</v-icon></span>
            <span class="ml-3">Repeat</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Order'"
          @click="sendToParent('info')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="d-flex align-center"
              :name="'info'"
              :width="'20'"
              :height="'20'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Info</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Order' && this.currentData[0]['tab'] == 'Pending'"
          @click="sendToParent('cancel')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-close</v-icon></span>
            <span class="ml-3">Cancel</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Alert' || page == 'Gtt'"
          @click="sendToParent('cancel')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-close</v-icon></span>
            <span class="ml-3">Cancel</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Order' && this.currentData[0]['tab'] !== 'Baskets'"
          @click="sendToParent('chart')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'chart'"
              :width="'24'"
              :height="'24'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Chart</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Order' && this.currentData[0]['tab'] == 'Baskets'"
          @click="sendToParent('edit')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-pencil-outline</v-icon></span>
            <span class="ml-3">Edit</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Order' && this.currentData[0]['tab'] == 'Baskets'"
          @click="sendToParent('Details')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-details</v-icon></span>
            <span class="ml-3">Details</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Order' && this.currentData[0]['tab'] == 'Baskets'"
          @click="sendToParent('delete')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'delete'"
              :width="'20'"
              :height="'20'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Delete</span>
          </div>
        </li>
        <!-- Positions  -->
        <li
          class="bottomSheetList"
          v-if="
            page == 'Position' && this.currentData[0]['item']['Netqty'] != '0'
          "
          @click="sendToParent('Add')"
        >
          <div class="d-flex align-center">
            <span>{{
              Number(this.currentData[0]["item"]["Netqty"]) > 0
                ? "&#43;"
                : "&#8722;"
            }}</span>
            <span class="ml-3">Add</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'Position' && this.currentData[0]['item']['Netqty'] != '0'
          "
          @click="sendToParent('Exit')"
        >
          <div class="d-flex align-center">
            <span>{{
              Number(this.currentData[0]["item"]["Netqty"]) > 0
                ? "&#8722;"
                : "&#43;"
            }}</span>
            <span class="ml-3">Exit</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'Position' && this.currentData[0]['item']['Netqty'] == '0'
          "
          @click="sendToParent('buy')"
        >
          <div class="d-flex align-center">
            <span>&#43;</span>
            <span class="ml-3">Buy</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'Position' && this.currentData[0]['item']['Netqty'] == '0'
          "
          @click="sendToParent('sell')"
        >
          <div class="d-flex align-center">
            <span>&#8722;</span>
            <span class="ml-3">Sell</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'Position' &&
            this.currentData[0]['item']['Netqty'] != '0' &&
            this.currentData[0]['item']['product'] != 'CO' &&
            this.currentData[0]['item']['product'] != 'BO'
          "
          @click="sendToParent('Convert')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-repeat-variant</v-icon></span>
            <span class="ml-3">Convert</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="
            page == 'Position' &&
            this.currentData[0]['item']['Netqty'] != '0' &&
            this.currentData[0]['item']['product'] != 'CO' &&
            this.currentData[0]['item']['product'] != 'BO'
          "
          @click="sendToParent('squareOff')"
        >
          <div class="d-flex align-center">
            <span><v-icon size="20">mdi-exit-to-app</v-icon></span>
            <span class="ml-3">Exit Position</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Position'"
          @click="sendToParent('chart')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'chart'"
              :width="'24'"
              :height="'24'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Chart</span>
          </div>
        </li>
        <!-- Holdings  -->
        <li
          class="bottomSheetList"
          v-if="page == 'Holdings'"
          @click="sendToParent('Add')"
        >
          <div class="d-flex align-center">
            <span>&#43;</span>
            <span class="ml-3">Add</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Holdings'"
          @click="sendToParent('Exit')"
        >
          <div class="d-flex align-center">
            <span>&#8722;</span>
            <span class="ml-3">Exit</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Holdings'"
          @click="sendToParent('chart')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'chart'"
              :width="'24'"
              :height="'24'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Chart</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Sector'"
          @click="sendToParent('buy')"
        >
          <div class="d-flex align-center">
            <span>&#43;</span>
            <span class="ml-3">Buy</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Sector'"
          @click="sendToParent('sell')"
        >
          <div class="d-flex align-center">
            <span>&#8722;</span>
            <span class="ml-3">Sell</span>
          </div>
        </li>
        <li
          class="bottomSheetList"
          v-if="page == 'Sector'"
          @click="sendToParent('chart')"
        >
          <div class="d-flex align-center">
            <customIcon
              class="hoverImg d-flex align-center"
              :name="'chart'"
              :width="'24'"
              :height="'24'"
              :color="
                $vuetify.theme.dark
                  ? '#ffffff'
                  : $store.state.iconBlackColourCode
              "
            />
            <span class="ml-3">Chart</span>
          </div>
        </li>
      </ul>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import customIcon from "./customIcon.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      bottomSheet: false,
      page: "",
    };
  },
  components: {
    customIcon,
  },
  props: {
    currentData: Array,
    currentIndex: Number,
  },

  methods: {
    sendToParent(action) {
      this.bottomSheet = false;
      var temp = {
        action: action,
        page: this.page ? this.page : "",
        item: this.currentData[0]["item"] ? this.currentData[0]["item"] : "",
        index: this.currentIndex,
      };

      this.$emit("from-child", temp);
    },
  },

  watch: {
    currentData: function (val) {
      this.bottomSheet = val[0].isOpen;
      this.page = val[0]["where"];
    },
  },
};
</script>

<style>
.bottomSheetUl {
  list-style: none !important;
  padding: 0px !important;
}
.bottomSheetList {
  height: 60px;
  border-bottom: solid 1px #ededed;
  padding: 16px;
  display: flex;
  align-items: center;
}
</style>
